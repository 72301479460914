import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  exportOutstandingReport,
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  LogisticsSalesRegister,
} from "../../helpers/endpoints/api_endpoints";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Select } from "antd";
import { toast } from "react-toastify";
import { Loader } from "react-feather";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OutStandingReportsExport from "./OutStandingReportsExport";

const OutStandingReports = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [paymentType, setPaymentType] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [receivedDate, setReceivedDate] = useState(null);
  const [pendingAmount, setPendingAmount] = useState("");
  const [createdDate, setCreatedDate] = useState(null);
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approvals, setApprovals] = useState("");
  const [atmId, setAtmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  useEffect(() => {
    fetchClients();
    fetchCompanies();
    fetchInwardList();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const fetchInwardList = async (filters = {}, page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${LogisticsSalesRegister}?is_done=false`,
        {
          params: { ...filters, page },
        }
      );
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };

  const handleExport = async (filters = {}) => {
    console.log(filters);
    try {
      const response = await axiosInstance.get(exportOutstandingReport, {
        params: {
          company_id: filters.selectedCompany,
          client_id: filters.selectedClient,
        },
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outstanding_report.xlsx");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      toast.success("Report exported successfully");
    } catch (error) {
      console.error("Error exporting report: ", error);
      toast.error("Failed to export report");
    }
  };

  const handleFilterChange = () => {
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      client: selectedClient.id, // Correct key
      company: selectedCompany.id, // Correct key
      start_date: startDate,
      end_date: endDate,
      approvals: approvals,
      atm_id: atmId,
      type: type,
    };
    fetchInwardList(filters, currentPage);
  };

  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setEndDate("");
    setApprovals("");
    setAtmId("");
    setType("");
    setPaymentType("");
    setReceivedAmount("");
    setReceivedDate(null);
    setPendingAmount("");
    setCreatedDate(null);
    fetchInwardList({}, 1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      client: selectedClient.id, // Correct key
      company: selectedCompany.id, // Correct key
      start_date: startDate,
      end_date: endDate,
      approvals: approvals,
      atm_id: atmId,
      type: type,
    };
    fetchInwardList(filters, newPage);
  };

  useEffect(() => {
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      approvals: approvals,
      atm_id: atmId,
      type: type,
    };
    fetchInwardList(filters, currentPage);
  }, [
    paymentType,
    receivedAmount,
    receivedDate,
    pendingAmount,
    createdDate,
    selectedClient,
    selectedCompany,
    startDate,
    endDate,
    approvals,
    atmId,
    type,
    currentPage,
  ]);

  const calculateAge = (created) => {
    const createdDate = new Date(created);
    const currentDate = new Date();
    const timeDiff = currentDate - createdDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    return daysDiff;
  };

  const renderUniqueNames = (details, key) => {
    const uniqueNames = new Set();
    details.forEach((e) => {
      if (e[key]?.name) {
        uniqueNames.add(e[key]?.name);
      }
    });
    return Array.from(uniqueNames).map((name, index) => (
      <li key={index} className="text-start">
        {name}
      </li>
    ));
  };
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">OutStanding Reports</h2>

            <div className="d-flex mb-2">
              <Dropdown
                onSelect={(e) =>
                  setSelectedClient({
                    id: e,
                    name:
                      clients?.results?.find(
                        (client) => client.id === parseInt(e)
                      )?.name || "Select Client",
                  })
                }
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedClient.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {clients?.results?.map((client) => (
                    <Dropdown.Item key={client.id} eventKey={client.id}>
                      {client.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                className="mx-2"
                onSelect={(e) =>
                  setSelectedCompany({
                    id: e,
                    name:
                      companies?.results?.find(
                        (company) => company.id === parseInt(e)
                      )?.name || "Select Company",
                  })
                }
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedCompany.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {companies?.results?.map((company) => (
                    <Dropdown.Item key={company.id} eventKey={company.id}>
                      {company.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Form.Control
                className="mx-2"
                type="text"
                value={pendingAmount}
                onChange={(e) => setPendingAmount(e.target.value)}
                placeholder="Pending Amount"
              />

              <Form.Control
                type="text"
                value={receivedAmount}
                onChange={(e) => setReceivedAmount(e.target.value)}
                placeholder="Received Amount"
                className="me-2"
              />
            </div>
            <div>
              <Button variant="primary" onClick={handleFilterChange}>
                Apply Filters
              </Button>
              <Button
                className="ms-1"
                variant="secondary"
                onClick={handleResetFilters}
              >
                Reset Filters
              </Button>
            </div>
          </div>
          <div className="text-end mb-1">
            <Button variant="success" onClick={() => setShowExportModal(true)}>
              Export Report
            </Button>
          </div>
          {isLoading ? (
            <Table className="table table-striped text-center">
              <thead className="table-secondary">
                <tr>
                  <th>sl. No</th>
                  <th>Client Name</th>
                  <th>Billing Company</th>
                  <th>Invoice Number</th>
                  <th>Invoice Date</th>
                  <th>Invoice Value</th>
                  <th>Age (Days)</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td colSpan="9">
                    Loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <div className="table-responsive">
              <Table
                striped
                bordered
                hover
                className="table table-striped text-center"
              >
                <thead className="table-secondary">
                  <tr>
                    <th>sl. No</th>
                    <th>Client Name</th>
                    <th>Billing Company</th>
                    <th>Invoice Number</th>
                    <th>Invoice Date</th>
                    <th>Invoice Value</th>
                    <th>Age (Days)</th>
                  </tr>
                </thead>
                <tbody>
                  {inwardList?.results?.length > 0 ? (
                    inwardList.results.map((inward, index) => {
                      const age = calculateAge(inward.created);

                      return (
                        <tr key={inward.id}>
                          <td>{(currentPage - 1) * 20 + index + 1}</td>
                          <td>
                            {inward?.outwards_details?.length > 0
                              ? renderUniqueNames(
                                  inward?.outwards_details,
                                  "outward_client_name"
                                )
                              : "Not available"}
                          </td>
                          <td>
                            {inward?.outwards_details?.length > 0
                              ? renderUniqueNames(
                                  inward?.outwards_details,
                                  "outward_company_name"
                                )
                              : "Not available"}
                          </td>
                          <td>{inward.bill_number || "NA"}</td>
                          <td>{inward.bill_date || "NA"}</td>
                          <td>{inward.total_invoice || "NA"}</td>
                          <td>{age}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8">No data</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </div>
          <OutStandingReportsExport
            show={showExportModal}
            handleClose={() => setShowExportModal(false)}
            handleExport={handleExport}
          />
        </div>
      </div>
    </>
  );
};

export default OutStandingReports;
