import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
// import Charts from "./charts";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  ApprovalCounts,
  LogisticsDailyCounts,
  LogisticsMonthlyCounts,
} from "../../helpers/endpoints/api_endpoints";
import Charts from "./charts";
import DashboardInwardTable from "./DashboardInwardTable";
import DashboardOutwardTable from "./DashboardOutwardTable";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";

const Dashboard = () => {
  const { designation } = useSelector((state) => state.account);
  const [dailyCounts, setDailyCounts] = useState([]);
  const [monthlyCounts, setMonthlyCounts] = useState([]);
  const [approvalCounts, setApprovalCount] = useState([]);
  console.log(designation);
  useEffect(() => {
    axiosInstance.get(`${LogisticsDailyCounts}`).then(
      (response) => {
        console.log(response.data);
        setDailyCounts(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
    axiosInstance.get(`${LogisticsMonthlyCounts}`).then(
      (response) => {
        console.log(response.data);
        setMonthlyCounts(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
    axiosInstance.get(`${ApprovalCounts}`).then(
      (response) => {
        console.log(response.data);
        setApprovalCount(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const getTodayCounts = (dailyInward, dailyOutward) => {
    const today = new Date().toISOString().split("T")[0];

    const inwardToday = dailyInward?.find((entry) => entry.day === today) || {
      day: today,
      count: 0,
    };
    const outwardToday = dailyOutward?.find((entry) => entry.day === today) || {
      day: today,
      count: 0,
    };

    return { inwardToday, outwardToday };
  };

  const { inwardToday, outwardToday } = getTodayCounts(
    dailyCounts?.daily_inward,
    dailyCounts?.daily_outward
  );

  const getCurrentMonth = () => {
    const options = { month: "long" };
    return new Date().toLocaleDateString("en-US", options);
  };

  const getMonthlyCounts = (monthlyInward, monthlyOutward, currentMonth) => {
    const inwardMonth = monthlyInward?.find(
      (entry) => entry.month === currentMonth
    ) || { month: currentMonth, count: 0 };
    const outwardMonth = monthlyOutward?.find(
      (entry) => entry.month === currentMonth
    ) || { month: currentMonth, count: 0 };

    return { inwardMonth, outwardMonth };
  };
  const { monthly_inward, monthly_outward } = monthlyCounts;
  const currentMonth = getCurrentMonth();
  const { inwardMonth, outwardMonth } = getMonthlyCounts(
    monthly_inward,
    monthly_outward,
    currentMonth
  );
  console.log(inwardMonth, outwardMonth);
  return (
    <>
      <Header />
      <AdminSideBar />

      <div className="main-wrapper">
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="d-flex justify-content-between align-items-center mb-1">
              <h2 className="card-title">Dashboard</h2>
            </div>
            {/* /Page Header */}

            {/* Cards  */}
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-clock`} />
                    <div className="dash-widget-info">
                      <h4>Daily Inward</h4>
                      <h3>{inwardToday?.count ? inwardToday?.count : "NA"}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-clock`} />
                    <div className="dash-widget-info">
                      <h4>Daily Outward</h4>
                      <h3>
                        {outwardToday?.count ? outwardToday?.count : "NA"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-calendar `} />
                    <div className="dash-widget-info">
                      <h4>Monthly Inward</h4>
                      <h3>{inwardMonth?.count ? inwardMonth?.count : "NA"}</h3>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                <div className="card dash-widget">
                  <div className="card-body">
                    <span className={`dash-widget-icon fa fa-calendar`} />
                    <div className="dash-widget-info">
                      <h4>Monthly Outward</h4>
                      <h3>
                        {outwardMonth?.count ? outwardMonth?.count : "NA"}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {designation === "Accounts Manager" ||
              designation === "⁠Area Manager" ||
              designation === "⁠Zonal Manager" ? (
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-4">
                  <div className="card dash-widget">
                    <div className="card-body">
                      <span
                        className={`dash-widget-icon fa fa-hourglass-half`}
                      />
                      <div className="dash-widget-info">
                        <h4>Pending Approvals</h4>
                        {designation === "Accounts Manager" && (
                          <h3>{approvalCounts?.accounts_manager || "NA"}</h3>
                        )}
                        {designation === "⁠Area Manager" && (
                          <h3> {approvalCounts?.area_manager || "NA"}</h3>
                        )}
                        {designation === "⁠Zonal Manager" && (
                          <h3>{approvalCounts?.zonal_manager || "NA"}</h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {designation === "Finance Manager" ||
              designation === "Country Head" ||
              designation === "Director" ? (
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-12">
                  <div className="card dash-widget">
                    <div className="card-body">
                      {/* <span className="dash-widget-icon fa fa-calendar" /> */}
                      <div className="dash-widget-info">
                        <h4 className="text-start mb-2">Pending Approvals</h4>

                        {designation === "Finance Manager" ||
                        designation === "Country Head" ||
                        designation === "Director" ? (
                          <Table bordered hover className="text-center">
                            <thead className="table-secondary">
                              <tr>
                                <th>Finance Manager</th>
                                <th>Country Head</th>
                                <th>Accounts Manager</th>
                                <th>Area Manager</th>
                                <th>Director</th>
                                <th>Zonal Manager</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {approvalCounts?.finance_manager || "NA"}
                                </td>
                                <td>{approvalCounts?.country_head || "NA"}</td>
                                <td>
                                  {approvalCounts?.accounts_manager || "NA"}
                                </td>{" "}
                                <td>{approvalCounts?.area_manager || "NA"}</td>
                                <td>{approvalCounts?.director || "NA"}</td>
                                <td>{approvalCounts?.zonal_manager || "NA"}</td>
                              </tr>
                            </tbody>
                          </Table>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* Cards  */}

            {/* /Charts */}
            <Charts dailyCounts={dailyCounts} monthlyCounts={monthlyCounts} />
            {/* /Charts */}

            <div className="row">
              <DashboardInwardTable />

              <DashboardOutwardTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
