import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  DocketDownloadUrl,
  LogisticsActivityPhoto,
  LogisticsActivityPod,
  LogisticsApprovalPdf,
  LogisticsCalculateTotal,
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  exportOutwardData,
  outwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Download, Filter, Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AddSalesModal from "./AddSalesModal";
import ExportModal from "./ExportModal";
import { FaExclamationCircle } from "react-icons/fa";

function ViewDetailsModal({
  show,
  handleClose,
  updateOutwardInList,
  removeOutwardFromList,
}) {
  const { designation } = useSelector((state) => state.account);
  console.log(designation);
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productList, setProductList] = useState([]);
  const [clientList, setClientList] = useState([]);
  // Remove invisible characters and trim
  const normalizedDesignation = designation
    .replace(/[\u200B-\u200D\uFEFF]/g, "")
    .trim();
  console.log(normalizedDesignation);
  useEffect(() => {
    if (show) {
      axiosInstance.get(`${outwardFormUrl}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Save original data
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch products data
      axiosInstance.get(LogisticsCompaniesURL).then(
        (response) => {
          setProductList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch client data
      axiosInstance.get(LogisticsClientsURL).then(
        (response) => {
          setClientList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
      setOriginalData(null); // Reset original data
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "client" ? value : label && name === "company" ? value : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };

  const handleGiveAccess = () => {
    axiosInstance
      .patch(`${outwardFormUrl}${show}/`, {
        is_onupdate: true,
      })
      .then(
        (response) => {
          console.log(response.data);
          updateOutwardInList(response.data);
          toast.success("Success!");
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const changedData = {
      is_onupdate: false,
    };
    for (let key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    console.log("Changed Data: ", changedData);

    axiosInstance.patch(`${outwardFormUrl}${show}/`, changedData).then(
      (response) => {
        console.log(response.data);
        updateOutwardInList(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${outwardFormUrl}${show}/`).then(
      () => {
        removeOutwardFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));

  const CompanyList = productList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));
  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Outward Form Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formType">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">State</label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={stateNames}
                          placeholder="Select State"
                          onChange={(value, option) =>
                            handleSelectChange("state", value, option.label)
                          }
                          required
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formClient">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">Client </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={ClientList}
                          placeholder="Select Client Name"
                          onChange={(value, option) =>
                            handleSelectChange("client", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formCompany">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">
                        Company Name
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={CompanyList}
                          placeholder="Select Company Name"
                          onChange={(value, option) =>
                            handleSelectChange("company", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formDocketNumber">
                    <Form.Label className="mb-0 mt-2">
                      Client Docket Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="client_docket_number"
                      value={formData.client_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceNumber">
                    <Form.Label className="mb-0 mt-2">
                      Invoice Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_number"
                      value={formData.client_invoice_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceNumber">
                    <Form.Label className="mb-0 mt-2">ATM ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="atm_id"
                      value={formData.atm_id}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceValue">
                    <Form.Label className="mb-0 mt-2">Invoice Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_value"
                      value={formData.client_invoice_value}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceDate">
                    <Form.Label className="mb-0 mt-2">Invoice Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="client_invoice_date"
                      value={formData.client_invoice_date}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVtDocketNumber">
                    <Form.Label className="mb-0 mt-2">
                      VT Docket Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="vt_docket_number"
                      value={formData.vt_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formMode">
                    <Form.Label className="mb-0 mt-2">Mode</Form.Label>
                    <Form.Control
                      type="text"
                      name="mode"
                      value={formData.mode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSender">
                    <Form.Label className="mb-0 mt-2">Sender</Form.Label>
                    <Form.Control
                      type="text"
                      name="sender"
                      value={formData.sender}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVehicleNumber">
                    <Form.Label className="mb-0 mt-2">
                      Vehicle Number
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicle_number"
                      value={formData.vehicle_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formVehicleType">
                    <Form.Label className="mb-0 mt-2">Vehicle Type</Form.Label>
                    <Form.Control
                      type="text"
                      name="vehicle_type"
                      value={formData.vehicle_type}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formDriverStaffName">
                    <Form.Label className="mb-0 mt-2">
                      Driver Staff Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="driver_staff_name"
                      value={formData.driver_staff_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formOmNumber">
                    <Form.Label className="mb-0 mt-2">OM Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="om_number"
                      value={formData.om_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formActivityCost">
                    <Form.Label className="mb-0 mt-2">Activity Cost</Form.Label>
                    <Form.Control
                      type="text"
                      name="activity_cost"
                      value={formData.activity_cost}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formFromAddress">
                    <Form.Label className="mb-0 mt-2">From Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_name"
                      value={formData.from_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromAddress">
                    <Form.Label className="mb-0 mt-2">From Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_address"
                      value={formData.from_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromPincode">
                    <Form.Label className="mb-0 mt-2">From Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_pincode"
                      value={formData.from_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromContact">
                    <Form.Label className="mb-0 mt-2">From Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_contact"
                      value={formData.from_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromEmail">
                    <Form.Label className="mb-0 mt-2">From Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label className="mb-0 mt-2">To Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_name"
                      value={formData.to_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label className="mb-0 mt-2">To Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_address"
                      value={formData.to_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToPincode">
                    <Form.Label className="mb-0 mt-2">To Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_pincode"
                      value={formData.to_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToContact">
                    <Form.Label className="mb-0 mt-2">To Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_contact"
                      value={formData.to_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToEmail">
                    <Form.Label className="mb-0 mt-2">To Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="to_email"
                      value={formData.to_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formAbortiveCharge">
                      <Form.Label className="mb-0 mt-2">
                        Abortive Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="abortive_charge"
                        label="Abortive Charge"
                        value={formData.abortive_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formAccessoriesCharge">
                      <Form.Label className="mb-0 mt-2">
                        Accessories Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="accessories_charge"
                        label="Accessories Charge"
                        value={formData.accessories_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formDismantlingCharge">
                      <Form.Label className="mb-0 mt-2">
                        Dismantling Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="dismantling_charge"
                        label="Dismantling Charge"
                        value={formData.dismantling_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formFirstFloorCharge">
                      <Form.Label className="mb-0 mt-2">
                        First Floor Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="first_floor_charge"
                        label="First Floor Charge"
                        value={formData.first_floor_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formFreightCharge">
                      <Form.Label className="mb-0 mt-2">
                        Freight Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="freight_charge"
                        label="Freight Charge"
                        value={formData.freight_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formDeGrouting">
                      <Form.Label className="mb-0 mt-2">
                        De Grouting Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="de_grouting_charge"
                        label="De Grouting Charge"
                        value={formData.de_grouting_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formGroutingCharge">
                      {" "}
                      <Form.Label className="mb-0 mt-2">
                        {" "}
                        Grouting Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="grouting_charge"
                        label="Grouting Charge"
                        value={formData.grouting_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formOthersCharge">
                      {" "}
                      <Form.Label className="mb-0 mt-2">
                        Other Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="others_charge"
                        label="Other Charge"
                        value={formData.others_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formPackingCharge">
                      {" "}
                      <Form.Label className="mb-0 mt-2">
                        Packing Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="packing_charge"
                        label="Packing Charge"
                        value={formData.packing_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {normalizedDesignation === "Finance Manager" && (
                    <Form.Group controlId="formUnionChargeCharge">
                      <Form.Label className="mb-0 mt-2">
                        Union Charge Charge
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="union_charge_charge"
                        label="Union Charge Charge"
                        value={formData.union_charge_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}

                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formAbortive">
                      <Form.Check
                        type="checkbox"
                        name="abortive"
                        label="Abortive"
                        checked={formData.abortive}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}

                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formAccessories">
                      <Form.Check
                        type="checkbox"
                        name="accessories"
                        label="Accessories"
                        checked={formData.accessories}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formDismantling">
                      <Form.Check
                        type="checkbox"
                        name="dismantling"
                        label="Dismantling"
                        checked={formData.dismantling}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formFirstFloor">
                      <Form.Check
                        type="checkbox"
                        name="first_floor"
                        label="First Floor"
                        checked={formData.first_floor}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formFreight">
                      <Form.Check
                        type="checkbox"
                        name="freight"
                        label="Freight"
                        checked={formData.freight}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formDeGrouting">
                      <Form.Check
                        type="checkbox"
                        name="de_grouting"
                        label="De Grouting"
                        checked={formData.de_grouting}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formGrouting">
                      <Form.Check
                        type="checkbox"
                        name="grouting"
                        label="Grouting"
                        checked={formData.grouting}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formOthers">
                      <Form.Check
                        type="checkbox"
                        name="others"
                        label="Others"
                        checked={formData.others}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formPacking">
                      <Form.Check
                        type="checkbox"
                        name="packing"
                        label="Packing"
                        checked={formData.packing}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  {formData?.approved_by_finance_manager !== true && (
                    <Form.Group controlId="formUnionCharge">
                      <Form.Check
                        type="checkbox"
                        name="union_charge"
                        label="Union Charge"
                        checked={formData.union_charge}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId="formRemarks">
                    <Form.Label className="mb-0 mt-2">Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSpecify">
                    <Form.Label className="mb-0 mt-2">Specify</Form.Label>
                    <Form.Control
                      type="text"
                      name="specify"
                      value={formData.specify}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {formData.outward_products &&
                    formData.outward_products.length > 0 && (
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            <th>Weight</th>
                            <th>Pieces</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.outward_products?.map((product) => (
                            <tr key={product.id}>
                              <td>{product.ow_product_name?.name || "N/A"}</td>
                              <td>{product.weight || "N/A"}</td>
                              <td>{product.pieces || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}

                  {/* <Button variant="primary" type="submit">
                    Save Changes
                  </Button> */}
                </Form>
              ) : (
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Created Date</th>
                      <td>
                        {formData.created_at
                          ? new Date(formData.created_at).toLocaleString()
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>Completion Date</th>
                      <td>
                        {formData.updated_at
                          ? new Date(formData.updated_at).toLocaleString()
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{formData?.state || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Client</th>
                      <td>{formData.client_details?.name || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td>{formData.company_details?.name || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Client Docket Number</th>
                      <td>{formData.client_docket_number || "NA"}</td>
                    </tr>
                    {designation === "State Manager" ||
                    designation === "Area Manager" ? (
                      ""
                    ) : (
                      <tr>
                        <th>Activity Cost</th>
                        <td>{formData.activity_cost || "NA"}</td>
                      </tr>
                    )}
                    <tr>
                      <th>Invoice Number</th>
                      <td>{formData.client_invoice_number || "NA"}</td>
                    </tr>
                    <tr>
                      <th>ATM ID</th>
                      <td>{formData.atm_id || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Invoice Value</th>
                      <td>{formData.client_invoice_value || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Invoice Date</th>
                      <td>{formData.client_invoice_date || "NA"}</td>
                    </tr>
                    <tr>
                      <th>VT Docket Number</th>
                      <td>{formData.vt_docket_number || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Mode</th>
                      <td>{formData.mode || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Sender</th>
                      <td>{formData.sender || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Vehicle Number</th>
                      <td>{formData.vehicle_number || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Vehicle Type</th>
                      <td>{formData.vehicle_type || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Driver Staff Name</th>
                      <td>{formData.driver_staff_name || "NA"}</td>
                    </tr>
                    <tr>
                      <th>OM Number</th>
                      <td>{formData.om_number || "NA"}</td>
                    </tr>
                    {/* <tr>
                      <th>Activity Cost</th>
                      <td>{formData.activity_cost}</td>
                    </tr> */}
                    <tr>
                      <th>From Name</th>
                      <td>{formData.from_name || "NA"}</td>
                    </tr>
                    <tr>
                      <th>From Address</th>
                      <td>{formData.from_address || "NA"}</td>
                    </tr>
                    <tr>
                      <th>From Pincode</th>
                      <td>{formData.from_pincode || "NA"}</td>
                    </tr>
                    <tr>
                      <th>From Contact</th>
                      <td>{formData.from_contact || "NA"}</td>
                    </tr>
                    <tr>
                      <th>From Email</th>
                      <td>{formData.from_email || "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Name</th>
                      <td>{formData.to_name || "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Address</th>
                      <td>{formData.to_address || "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Pincode</th>
                      <td>{formData.to_pincode || "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Contact</th>
                      <td>{formData.to_contact || "NA"}</td>
                    </tr>
                    <tr>
                      <th>To Email</th>
                      <td>{formData.to_email || "NA"}</td>
                    </tr>
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Abortive</th>
                        <td>{formData.abortive ? "Yes" : "No"}</td>
                      </tr>
                    )}
                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Abortive</th>
                        <td>{formData.abortive ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Abortive Charge</th>
                          <td>{formData.abortive_charge || "NA"}</td>
                        </tr>
                      )}
                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Accessories Charge</th>
                          <td>{formData.accessories_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Accessories</th>
                        <td>{formData.accessories ? "Yes" : "No"}</td>
                      </tr>
                    )}
                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Accessories</th>
                        <td>{formData.accessories ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Dismantling</th>
                        <td>{formData.dismantling ? "Yes" : "No"}</td>
                      </tr>
                    )}
                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Dismantling</th>
                        <td>{formData.dismantling ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Dismantling Charge</th>
                          <td>{formData.dismantling_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>First Floor</th>
                        <td>{formData.first_floor ? "Yes" : "No"}</td>
                      </tr>
                    )}
                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>First Floor</th>
                        <td>{formData.first_floor ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>First Floor Charge</th>
                          <td>{formData.first_floor_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Freight</th>
                        <td>{formData.freight ? "Yes" : "No"}</td>
                      </tr>
                    )}
                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Freight</th>
                        <td>{formData.freight ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}
                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Freight Charge</th>
                          <td>{formData.freight_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>De Grouting</th>
                        <td>{formData.de_grouting ? "Yes" : "No"}</td>
                      </tr>
                    )}

                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>De Grouting</th>
                        <td>{formData.de_grouting ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>De Grouting Charge</th>
                          <td>{formData.de_grouting_charges || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Grouting</th>
                        <td>{formData.grouting ? "Yes" : "No"}</td>
                      </tr>
                    )}

                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th> Grouting</th>
                        <td>{formData.grouting ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Grouting Charge</th>
                          <td>{formData.grouting_charge || "NA"}</td>
                        </tr>
                      )}

                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Others</th>
                        <td>{formData.others ? "Yes" : "No"}</td>
                      </tr>
                    )}

                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th> Others</th>
                        <td>{formData.others ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Others Charge</th>
                          <td>{formData.others_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Packing</th>
                        <td>{formData.packing ? "Yes" : "No"}</td>
                      </tr>
                    )}

                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Packing</th>
                        <td>{formData.packing ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Packing Charge</th>
                          <td>{formData.packing_charge || "NA"}</td>
                        </tr>
                      )}
                    {formData?.approved_by_finance_manager !== true && (
                      <tr>
                        <th>Union Charge</th>
                        <td>{formData.union_charge ? "Yes" : "No"}</td>
                      </tr>
                    )}

                    {normalizedDesignation === "Area Manager" ||
                    normalizedDesignation === "State Manager" ? (
                      <tr>
                        <th>Union Charge</th>
                        <td>{formData.union_charge ? "Yes" : "No"}</td>
                      </tr>
                    ) : (
                      ""
                    )}

                    {normalizedDesignation !== "Area Manager" &&
                      normalizedDesignation !== "State Manager" && (
                        <tr>
                          <th>Union Charge Charge</th>
                          <td>{formData.union_charge_charge || "NA"}</td>
                        </tr>
                      )}
                    <tr>
                      <th>Remarks</th>
                      <td>{formData.remarks || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Specify</th>
                      <td>{formData.specify || "NA"}</td>
                    </tr>
                    <tr>
                      <th>Products</th>
                      <td>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Pieces</th>
                              <th>Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.outward_products?.map(
                              (product, index) => (
                                <tr key={index}>
                                  <td>{product.ow_product_name?.name}</td>
                                  <td>{product.pieces}</td>
                                  <td>{product.weight}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>Photos</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.photos?.map((product, index) => (
                              <div key={index}>
                                <div>
                                  <a
                                    href={product.photos} // Use href for external links
                                    className="btn btn-success text-white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Image {index + 1}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>POD</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.pod.map((product, index) => (
                              <div key={index}>
                                <div>
                                  <a
                                    href={product.photos} // Use href for external links
                                    className="btn btn-success text-white"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Image {index + 1}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                    <tr>
                      <th>Approval Mail</th>
                      <td>
                        <Table striped bordered hover>
                          <tbody className="d-flex flex-wrap align-items-center justify-content-center">
                            {formData.approval_mail.map((product, index) => (
                              <div key={index}>
                                <div>
                                  <Link
                                    to={product.photos}
                                    className="btn btn-success text-white"
                                  >
                                    File {index + 1}
                                  </Link>
                                </div>
                              </div>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="me-2"
                >
                  Close
                </Button>
                {isEditing ? (
                  <Button variant="success" onClick={handleSubmit}>
                    Save
                  </Button>
                ) : formData?.approved_by_area_manager === false ? (
                  <>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                    {designation === "Director" && (
                      <Button
                        className="ms-1 "
                        variant="primary"
                        onClick={handleGiveAccess}
                      >
                        Give Edit Access
                      </Button>
                    )}
                  </>
                ) : designation === "Finance Manager" &&
                  formData?.is_onupdate === true ? (
                  <Button variant="primary" onClick={handleEditToggle}>
                    Edit
                  </Button>
                ) : (
                  ""
                )}
                {/* <Button
                  variant="danger"
                  onClick={openDeleteConfirmation}
                  className="ms-2"
                >
                  Delete
                </Button> */}
              </div>
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this outward form?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

function ViewAddDocModal({ show, handleClose }) {
  const [formData, setFormData] = useState({
    reference_number: "",
    invoice_value: "",
    invoice_number: "",
    invoice_date: "",
  });
  const [activityPhoto, setActivityPhoto] = useState([]);
  const [pod, setPod] = useState([]);
  const [approvalPdf, setApprovalPdf] = useState([]);
  const [isActivityPhotoSubmitted, setIsActivityPhotoSubmitted] =
    useState(false);
  const [isPodSubmitted, setIsPodSubmitted] = useState(false);
  const [isApprovalPdfSubmitted, setIsApprovalPdfSubmitted] = useState(false);
  const { designation } = useSelector((state) => state.account);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${outwardFormUrl}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setIsActivityPhotoSubmitted(response.data.photos?.length > 0);
          setIsPodSubmitted(response.data.pod?.length > 0);
          setIsApprovalPdfSubmitted(response.data?.approval_mail?.length > 0);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [show]);

  const handleFileChange = (e, setFile) => {
    setFile([...e.target.files]);
  };

  const handleSubmit = (files, endpoint, setSubmitted) => {
    console.log(files);
    const formData = new FormData();
    files.forEach((file) => formData.append("photos", file));
    formData.append("outward", show);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }

    axiosInstance
      .post(endpoint, formData, config)
      .then((response) => {
        console.log(response.data);
        toast.success("Uploaded successfully");
        setSubmitted(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error?.response?.data?.photos?.[0]) {
          toast.error("Please select a file");
        }
      });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmitReferenceNumber = () => {
    const data = {
      reference_number: formData.reference_number,
    };

    axiosInstance.patch(`${outwardFormUrl}${show}/`, data).then(
      (response) => {
        console.log(response.data);
        toast.success("Reference number saved successfully");
        // Clear the reference number field after submission
        setFormData((prevData) => ({
          ...prevData,
          reference_number: "",
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleSubmitInvoiceData = () => {
    const data = {
      client_invoice_value: formData.invoice_value,
      client_invoice_number: formData.invoice_number,
      client_invoice_date: formData.invoice_date,
    };

    axiosInstance.patch(`${outwardFormUrl}${show}/`, data).then(
      (response) => {
        console.log(response.data);
        toast.success("Invoice data saved successfully");
        // Clear the invoice fields after submission
        setFormData((prevData) => ({
          ...prevData,
          invoice_value: "",
          invoice_number: "",
          invoice_date: "",
        }));
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Documents
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Reference Number Field */}
            <Form.Group controlId="referenceNumber">
              <Form.Label className="mb-0 mt-2">Reference Number</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  name="reference_number"
                  value={formData.reference_number || ""}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
            <Button
              className="btn btn-primary btn-sm mt-2"
              onClick={handleSubmitReferenceNumber}
            >
              Submit Reference Number
            </Button>

            {/* Invoice Fields */}
            <Form.Group controlId="invoiceValue">
              <Form.Label className="mb-0 mt-2">Invoice Value</Form.Label>
              <Form.Control
                type="text"
                name="invoice_value"
                value={formData.invoice_value || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="invoiceNumber">
              <Form.Label className="mb-0 mt-2">Invoice Number</Form.Label>
              <Form.Control
                type="text"
                name="invoice_number"
                value={formData.invoice_number || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="invoiceDate">
              <Form.Label className="mb-0 mt-2">Invoice Date</Form.Label>
              <Form.Control
                type="date"
                name="invoice_date"
                value={formData.invoice_date || ""}
                onChange={handleChange}
              />
            </Form.Group>

            <Button
              className="btn btn-primary btn-sm mt-2"
              onClick={handleSubmitInvoiceData}
            >
              Submit Invoice Data
            </Button>

            {/* Existing Form Fields */}
            <Form.Group controlId="formActivityPhoto">
              <Form.Label className="mb-0 mt-2">Activity Data</Form.Label>
              <div className="d-flex justify-content-center align-items-center">
                {isActivityPhotoSubmitted ? (
                  <span>Already submitted</span>
                ) : (
                  <>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => handleFileChange(e, setActivityPhoto)}
                    />
                    <Button
                      className="btn btn-primary btn-sm p-2 ms-2"
                      onClick={() =>
                        handleSubmit(
                          activityPhoto,
                          LogisticsActivityPhoto,
                          setIsActivityPhotoSubmitted
                        )
                      }
                    >
                      Upload
                    </Button>
                  </>
                )}
              </div>
            </Form.Group>

            <Form.Group controlId="formPOD">
              <Form.Label className="mb-0 mt-2">POD</Form.Label>
              <div className="d-flex justify-content-center align-items-center">
                {isPodSubmitted ? (
                  <span>Already submitted</span>
                ) : (
                  <>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => handleFileChange(e, setPod)}
                    />
                    <Button
                      className="btn btn-primary btn-sm p-2 ms-2"
                      onClick={() =>
                        handleSubmit(
                          pod,
                          LogisticsActivityPod,
                          setIsPodSubmitted
                        )
                      }
                    >
                      Upload
                    </Button>
                  </>
                )}
              </div>
            </Form.Group>

            <Form.Group controlId="formApprovalPdf">
              <Form.Label className="mb-0 mt-2">Approval PDF</Form.Label>
              <div className="d-flex justify-content-center align-items-center">
                {isApprovalPdfSubmitted ? (
                  <span>Already submitted</span>
                ) : (
                  <>
                    <Form.Control
                      type="file"
                      multiple
                      onChange={(e) => handleFileChange(e, setApprovalPdf)}
                    />
                    <Button
                      className="btn btn-primary btn-sm p-2 ms-2"
                      onClick={() =>
                        handleSubmit(
                          approvalPdf,
                          LogisticsApprovalPdf,
                          setIsApprovalPdfSubmitted
                        )
                      }
                    >
                      Upload
                    </Button>
                  </>
                )}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const OutwardList = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [showAddDoc, setShowAddDoc] = useState(false);
  const [showAddSalesModal, setShowAddSalesModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approvals, setApprovals] = useState("");
  const [state, setState] = useState(""); // State for type filter
  const [atmId, setAtmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedOutwards, setSelectedOutwards] = useState([]);
  const [calculatedData, setCalculatedData] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [clientDocket, setClientDocket] = useState();
  console.log(clientDocket);
  const { designation } = useSelector((state) => state.account);

  useEffect(() => {
    fetchClients();
    fetchCompanies();
    fetchInwardList();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const fetchInwardList = async (filters = {}, page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(outwardFormUrl, {
        params: { ...filters, page },
      });
      console.log(response.data);
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = () => {
    const filters = {
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      // is_completed: approvals,
      atm_id: atmId,
      is_billed: approvals,
      client_docket_number: clientDocket,
      state: state,
    };
    setCurrentPage(1);
    fetchInwardList(filters, 1);
  };
  useEffect(() => {
    handleFilterChange();
  }, [
    selectedClient.id,
    selectedCompany.id,
    startDate,
    endDate,
    atmId,
    approvals,
    clientDocket,
    state,
  ]);
  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setEndDate("");
    setApprovals("");
    setAtmId("");
    setCurrentPage(1);
    fetchInwardList();
    setClientDocket(null);
    setState("");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const filters = {
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      is_completed: approvals,
      atm_id: atmId,
      client_docket_number: clientDocket,
      state: state,
    };
    fetchInwardList(filters, newPage);
  };

  const handleClose = () => setShow(false);
  const handleCloseAddDoc = () => setShowAddDoc(false);
  const handleCloseAddSales = () => setShowAddSalesModal(false);
  const handleShow = (id) => setShow(id);
  const handleShowAddDoc = (id) => setShowAddDoc(id);
  const handleShowAddSales = () => setShowAddSalesModal(true);

  const updateOutwardInList = (updatedOutward) => {
    setInwardList((prevList) => ({
      ...prevList,
      results: prevList.results.map((outward) =>
        outward.id === updatedOutward.id
          ? {
              ...outward,
              is_billed: updatedOutward.is_billed,
              freight_charge: updatedOutward.freight_charge,
            }
          : outward
      ),
    }));
  };

  const removeOutwardFromList = (id) => {
    setInwardList((prevList) => ({
      ...prevList,
      results: prevList.results.filter((outward) => outward.id !== id),
    }));
  };

  const handleExport = async (filters = {}) => {
    try {
      const response = await axiosInstance.get(exportOutwardData, {
        params: {
          company_name: filters.selectedCompany,
          client: filters.selectedClient,
          start_date: filters.startDate,
          end_date: filters.endDate,
        },
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outward_data.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting data: ", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedOutwards((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((outwardId) => outwardId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const eligibleOutwards = inwardList.results
        ?.filter(
          (item) => item.is_billed === false && item.approved_by_finance_manager
        )
        .map((item) => item.id);
      setSelectedOutwards(eligibleOutwards);
    } else {
      setSelectedOutwards([]);
    }
  };

  const handleCalculations = () => {
    const data = { outwards: selectedOutwards };
    axiosInstance.post(LogisticsCalculateTotal, data).then(
      (response) => setCalculatedData(response.data),
      (error) => console.error(error)
    );
  };

  const handleDocketDownload = async (item) => {
    console.log(item);
    try {
      const response = await axiosInstance.get(
        `logistics/generate-pdf/${item?.id}/`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${item?.vt_docket_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting data: ", error);
    }
  };
  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">OutWard List</h2>
            <div>
              <Button onClick={() => setShowExportModal(true)}>
                Export to Excel
                <Download
                  className="feather-download ms-2 mb-2"
                  data-bs-toggle="tooltip"
                  title="feather-download"
                />
              </Button>
              <ExportModal
                show={showExportModal}
                handleClose={() => setShowExportModal(false)}
                handleExport={handleExport}
              />
              {selectedOutwards.length > 0 &&
                (designation === "Finance Manager" ||
                  designation === "Director") && (
                  <Button
                    onClick={() => {
                      handleShowAddSales();
                      handleCalculations();
                    }}
                    className="ms-2"
                  >
                    Add Sales
                  </Button>
                )}
            </div>
          </div>

          <div className="d-flex mb-2 align-items-center">
            <Dropdown
              onSelect={(e) =>
                setSelectedClient({
                  id: e,
                  name:
                    clients.results.find((client) => client.id === parseInt(e))
                      ?.name || "Select Client",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedClient.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clients.results?.map((client) => (
                  <Dropdown.Item key={client.id} eventKey={client.id}>
                    {client.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="mx-2"
              onSelect={(e) =>
                setSelectedCompany({
                  id: e,
                  name:
                    companies.results.find(
                      (company) => company.id === parseInt(e)
                    )?.name || "Select Company",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedCompany.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {companies.results?.map((company) => (
                  <Dropdown.Item key={company.id} eventKey={company.id}>
                    {company.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="me-2" onSelect={(e) => setState(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {state ? state : "Select State"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {stateNames.map(({ value, label }) => (
                  <Dropdown.Item key={value} eventKey={value}>
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown onSelect={(e) => setApprovals(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {approvals
                  ? approvals === "true"
                    ? "Completed Bill"
                    : "Pending Bill"
                  : "Select Status"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="true">Completed Bill</Dropdown.Item>
                <Dropdown.Item eventKey="false">Pending Bill</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Form.Control
              className="mx-2"
              type="text"
              value={clientDocket}
              onChange={(e) => setClientDocket(e.target.value)}
              placeholder="Client Docket Number"
            />
            <Form.Control
              className="ms-2"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Form.Control
              className="mx-2"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className="text-end my-2">
            <Button
              className="me-2"
              variant="primary"
              onClick={handleFilterChange}
            >
              <Filter
                className="feather-filter me-1"
                data-bs-toggle="tooltip"
                title="feather-filter"
              />
              Apply Filters
            </Button>
            <Button variant="secondary" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </div>

          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Client Docket Number</th>
                <th>Invoice Number</th>
                <th>Reference Number</th>
                <th>ATM ID</th>
                <th>Actions</th>
                <th className="d-flex justify-content-center ">
                  {" "}
                  <Form.Check
                    className="ms-1"
                    type="checkbox"
                    label=" Select All Sales"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                inwardList.count > 0 ? (
                  inwardList.results?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>{item?.client_details?.name || "Not available"}</td>
                      <td>{item.client_docket_number || "Not Available"}</td>
                      <td>{item.client_invoice_number || "Not Available"}</td>
                      <td>{item.reference_number || "Not Available"}</td>
                      <td>{item.atm_id || "Not Available"}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1"
                          onClick={() => handleDocketDownload(item)}
                        >
                          <Download
                            height={15}
                            width={15}
                            className="feather-download  mb-1 my-0"
                            data-bs-toggle="tooltip"
                            title="feather-download"
                          />{" "}
                          Docket
                        </Button>

                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1 "
                          onClick={() => handleShow(item.id)}
                        >
                          View Details
                        </Button>
                        {item.is_billed === false ? (
                          <Button
                            variant="primary"
                            className="btn btn-sm ms-1 mt-1"
                            onClick={() => handleShowAddDoc(item.id)}
                          >
                            Add Documents
                          </Button>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        {" "}
                        {item.is_billed === false ? (
                          item.approved_by_finance_manager ? (
                            designation === "Finance Manager" ||
                            designation === "Director" ? (
                              <Button
                                variant="primary"
                                className="btn btn-sm ms-1"
                              >
                                <Form.Check
                                  className="pt-1"
                                  label=" add sales"
                                  type="checkbox"
                                  checked={selectedOutwards.includes(item.id)}
                                  onChange={() => handleCheckboxChange(item.id)}
                                />
                              </Button>
                            ) : null
                          ) : (
                            <p className="text-danger">
                              <FaExclamationCircle
                                style={{ marginRight: "5px" }}
                              />
                              Charges not added
                            </p>
                          )
                        ) : (
                          <Button variant="success" className="btn btn-sm ms-1">
                            Sales Done
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No data</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="9">
                    loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>

          <ViewDetailsModal
            show={show}
            handleClose={handleClose}
            updateOutwardInList={updateOutwardInList}
            removeOutwardFromList={removeOutwardFromList}
          />
          <ViewAddDocModal show={showAddDoc} handleClose={handleCloseAddDoc} />
          <AddSalesModal
            show={showAddSalesModal}
            handleClose={handleCloseAddSales}
            outwardIds={selectedOutwards}
            updateOutwardInList={updateOutwardInList}
            calculatedData={calculatedData}
          />
        </div>
      </div>
    </>
  );
};

export default OutwardList;
