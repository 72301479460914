import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Charts = (props) => {
  const { dailyCounts, monthlyCounts } = props;

  const linechartdata = dailyCounts.daily_inward?.map((item, index) => ({
    day: item.day,
    inward: item.count,
    outward:
      dailyCounts.daily_outward.find(
        (outwardItem) => outwardItem.day === item.day
      )?.count || 0,
  }));

  const barchartdata = monthlyCounts.monthly_inward?.map((item, index) => ({
    month: item.month,
    inward: item.count,
    outward:
      monthlyCounts.monthly_outward.find(
        (outwardItem) => outwardItem.month === item.month
      )?.count || 0,
  }));

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Daily Inward and Outward</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <LineChart
                      data={linechartdata}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="inward"
                        stroke="#ff9b44"
                        fill="#00c5fb"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                      <Line
                        type="monotone"
                        dataKey="outward"
                        stroke="#fc6075"
                        fill="#0253cc"
                        strokeWidth={3}
                        dot={{ r: 3 }}
                        activeDot={{ r: 7 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Monthly Inward and Outward</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={barchartdata}
                      margin={{
                        top: 5,
                        right: 5,
                        left: 5,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="inward" fill="#ff9b44" />
                      <Bar dataKey="outward" fill="#fc6075" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
