import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import { axiosInstance } from "../../../helpers/axios/axios";
import Swal from "sweetalert2";
import {
  createUser,
  departmentList,
  designationList,
} from "../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";

import AdminSideBar from "../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Header from "../../layout/Header";

const AllEmployee = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user, setUser] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeeId] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [gender, setGender] = useState("");
  const [countryVal, setCountryVal] = useState(null);
  const options = countryList().getData();
  const [maritalStatus, setMaritalStatus] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [religion, setReligion] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [empStatus, setEmpStatus] = useState("");
  const [empType, setEmpType] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentSelect, setDepartmentSelect] = useState("");
  const [designation, setDesigantion] = useState("");
  const [designationSelect, setDesigantionSelect] = useState("");
  const [reportingTo, setReportingTo] = useState("");
  const [probation, setProbation] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsStepTwo, setErrorsStepTwo] = useState({});
  const [nationalId, setNationalId] = useState({});

  useEffect(() => {
    axiosInstance
      .get(`${departmentList}`)
      .then((response) => {
        setDepartment(response.data);
      })
      .catch((err) => console.log(err));
    axiosInstance
      .get(`${designationList}`)
      .then((response) => {
        setDesigantion(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];
  const maritalStatusOptions = [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
    { label: "Divorced", value: "Divorced" },
    { label: "Other", value: "Other" },
  ];
  const religionOptions = [
    { label: "Christian", value: "Christian" },
    { label: "Hindu", value: "Hindu" },
    { label: "Muslim", value: "Muslim" },
    { label: "Other", value: "Other" },
  ];
  const bloodGroupOptions = [
    { label: "A-", value: "A-" },
    { label: "A+", value: "A+" },
    { label: "B-", value: "B-" },
    { label: "B+", value: "B+" },
    { label: "AB+", value: "AB+" },
    { label: "AB-", value: "AB-" },
    { label: "O+", value: "O+" },
    { label: "O-", value: "O-" },
  ];
  const employmentStatus = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Terminated", value: "Terminated" },
  ];
  const userType = [{ label: "Staff", value: "True" }];

  const employmentType = [
    { label: "Full Time", value: "Full Time" },
    { label: "Part Time", value: "Part Time" },
    { label: "Terminated", value: "Terminated" },
    { label: "Other", value: "Other" },
  ];
  // Check if department is an array before mapping over it
  const departmentOption = Array.isArray(department)
    ? department.map((data) => ({
        label: data.name,
        value: data.id.toString(), // Convert the id to string if it's not already
      }))
    : [];

  const designationOption = Array.isArray(designation)
    ? designation.map((data) => ({
        label: data.name,
        value: data.id.toString(), // Convert the id to string if it's not already
      }))
    : [];
  console.log(designationOption);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleCountryChange = (selectedOption) => {
    setCountryVal(selectedOption);
    console.log(selectedOption);
  };

  const handleSave = () => {
    const newErrors = {};
    const formData = {
      email,
      password,
      staff: user?.value,
      employee_name: employeeName,
      employee_id: employeeId,
      date_of_birth: dateOfBirth,
      gender: gender?.value,
      marital_status: maritalStatus?.value,
      mobile_number: mobileNumber,
      whatsapp_number: whatsAppNumber,
      religion: religion?.value,
      blood_group: bloodGroup?.value,
      personal_email: personalEmail,
      nationality: countryVal?.value,
      // national_id: nationalId,
      employment_status: empStatus?.value,
      department: departmentSelect?.value,
      designation: designationSelect?.value,
      effective_date: effectiveDate,
      employment_type: empType?.value,
    };

    console.log("Form Data:", formData);

    // Check for required fields
    if (!email.trim()) {
      newErrors.email = "Email is required";
    }
    if (!password.trim()) {
      newErrors.password = "Password is required";
    }
    if (!user || !user.value.trim()) {
      newErrors.user = "User is required";
    }
    if (!employeeName.trim()) {
      newErrors.employeeName = "Employee Name is required";
    }
    if (!employeeId.trim()) {
      newErrors.employeeId = "Employee Id is required";
    }
    if (!dateOfBirth.trim()) {
      newErrors.dateOfBirth = "Date of Birth is required";
    }
    if (!gender || !gender.value.trim()) {
      newErrors.gender = "Gender is required";
    }
    if (!countryVal) {
      newErrors.nationality = "Nationality is required";
    }
    if (!maritalStatus || !maritalStatus.value.trim()) {
      newErrors.maritalStatus = "Marital Status is required";
    }
    if (!mobileNumber.trim()) {
      newErrors.mobileNumber = "Mobile Number is required";
    }
    if (!whatsAppNumber.trim()) {
      newErrors.whatsAppNumber = "WhatsApp Number is required";
    }
    if (!religion || !religion.value.trim()) {
      newErrors.religion = "Religion is required";
    }
    if (!bloodGroup || !bloodGroup.value.trim()) {
      newErrors.bloodGroup = "Blood Group is required";
    }
    if (!personalEmail.trim()) {
      newErrors.personalEmail = "Personal Email is required";
    }
    if (!empStatus || !empStatus.value.trim()) {
      newErrors.empStatus = "Employment status is required";
    }
    if (!empType || !empType.value.trim()) {
      newErrors.empType = "Employment type is required";
    }
    if (!departmentSelect || !departmentSelect.value.trim()) {
      newErrors.departmentSelect = "Department is required";
    }
    if (!designationSelect || !designationSelect.value.trim()) {
      newErrors.designationSelect = "Designation is required";
    }

    setErrors(newErrors);

    // If there are no errors, proceed with saving the form data
    if (Object.keys(newErrors).length === 0) {
      axiosInstance
        .post(`${createUser}`, formData)
        .then((response) => {
          console.log(response.data);
          toast.success("Employee profile created successfully");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          setUser("");
          setEmployeeName("");
          setEmployeeeId("");
          setProfilePicture(null);
          setDateOfBirth("");
          setEffectiveDate("");
          setGender("");
          setCountryVal(null);
          setMaritalStatus("");
          setMobileNumber("");
          setWhatsAppNumber("");
          setReligion("");
          setBloodGroup("");
          setPersonalEmail("");
          setEmpStatus("");
          setEmpType("");
          setDepartmentSelect("");
          setDesigantionSelect("");
          setReportingTo("");
          setProbation("");
          setNationalId("");
          setErrors({});
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.data?.email) {
            toast.error(err.response?.data?.email[0]);
          }
          if (err.response?.data?.personal_email) {
            toast.error(err.response?.data?.personal_email[0]);
          }
          if (err.response?.data?.blood_group) {
            toast.error(err.response?.data?.blood_group[0]);
          }
        });
      console.log("Form saved successfully");
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="content-page-header">
              <h3>Add Employee</h3>
            </div>
          </div>
          {/* /Page Header */}
          <div className="row">
            {/* Lightbox */}
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">Enter Employee Details</h4>
                </div>
                <div className="card-body">
                  <div className="wizard">
                    {/* <ul
                      className="nav nav-tabs justify-content-center"
                      id="myTab"
                      role="tablist"
                    >
                      <li
                        className="nav-item flex-fill"
                        role="presentation"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Seller Details"
                      >
                        <Link
                          className="nav-link active rounded-circle mx-auto d-flex align-items-center justify-content-center"
                          to="#step1"
                          id="step1-tab"
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="step1"
                          aria-selected="true"
                        >
                          <i className="far fa-user" />
                        </Link>
                      </li>
                      <li
                        className="nav-item flex-fill"
                        role="presentation"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Company Document"
                      >
                        <Link
                          className="nav-link rounded-circle mx-auto d-flex align-items-center justify-content-center"
                          to="#step2"
                          id="step2-tab"
                          data-bs-toggle="tab"
                          role="tab"
                          aria-controls="step2"
                          aria-selected="false"
                        >
                          <i className="fas fa-map-pin" />
                        </Link>
                      </li>
                    </ul> */}
                    <div className="" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        role="tabpanel"
                        id="step1"
                        aria-labelledby="step1-tab"
                      >
                        <form action="#">
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Email
                            </label>
                            <div className="col-md-10">
                              <input
                                type="email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              {errors.email && (
                                <span className="text-danger">
                                  {errors.email}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Password
                            </label>
                            <div className="col-md-10">
                              <input
                                type="password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              {errors.password && (
                                <span className="text-danger">
                                  {errors.password}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              User
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={userType}
                                placeholder="Select..."
                                styles={customStyles}
                                value={user}
                                onChange={(selectedOption) =>
                                  setUser(selectedOption)
                                }
                              />
                              {errors.user && (
                                <span className="text-danger">
                                  {errors.user}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Employment Type
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={employmentType}
                                placeholder="Select..."
                                styles={customStyles}
                                value={empType}
                                onChange={(selectedOption) =>
                                  setEmpType(selectedOption)
                                }
                              />
                              {errors.empType && (
                                <span className="text-danger">
                                  {errors.empType}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Department
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={departmentOption}
                                placeholder="Select..."
                                styles={customStyles}
                                value={departmentSelect}
                                onChange={(selectedOption) =>
                                  setDepartmentSelect(selectedOption)
                                }
                              />
                              {errors.departmentSelect && (
                                <span className="text-danger">
                                  {errors.departmentSelect}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Designation
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={designationOption}
                                placeholder="Select..."
                                styles={customStyles}
                                value={designationSelect}
                                onChange={(selectedOption) =>
                                  setDesigantionSelect(selectedOption)
                                }
                              />
                              {errors.designationSelect && (
                                <span className="text-danger">
                                  {errors.designationSelect}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Employment Status
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={employmentStatus}
                                placeholder="Select..."
                                styles={customStyles}
                                value={empStatus}
                                onChange={(selectedOption) =>
                                  setEmpStatus(selectedOption)
                                }
                              />
                              {errors.designationSelect && (
                                <span className="text-danger">
                                  {errors.designationSelect}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Employee name
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                value={employeeName}
                                onChange={(e) =>
                                  setEmployeeName(e.target.value)
                                }
                              />
                              {errors.employeeName && (
                                <span className="text-danger">
                                  {errors.employeeName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Employee id
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                value={employeeId}
                                onChange={(e) => setEmployeeeId(e.target.value)}
                              />
                              {errors.employeeId && (
                                <span className="text-danger">
                                  {errors.employeeId}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Date of birth
                            </label>
                            <div className="col-md-10">
                              <input
                                type="date"
                                className="form-control"
                                value={dateOfBirth}
                                onChange={(e) => setDateOfBirth(e.target.value)}
                              />
                              {errors.dateOfBirth && (
                                <span className="text-danger">
                                  {errors.dateOfBirth}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Gender
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={genderOptions}
                                placeholder="Select..."
                                styles={customStyles}
                                value={gender}
                                onChange={(selectedOption) =>
                                  setGender(selectedOption)
                                }
                              />
                              {errors.gender && (
                                <span className="text-danger">
                                  {errors.gender}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Nationality
                            </label>
                            <div className="col-md-10">
                              <Select
                                isSearchable={true}
                                options={options}
                                value={countryVal}
                                onChange={handleCountryChange}
                              />
                              {errors.nationality && (
                                <span className="text-danger">
                                  {errors.nationality}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              National id
                            </label>
                            <div className="col-md-10">
                              <input
                                onChange={(e) => setNationalId(e.target.value)}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </div> */}
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Marital status
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={maritalStatusOptions}
                                placeholder="Select..."
                                styles={customStyles}
                                value={maritalStatus}
                                onChange={(selectedOption) =>
                                  setMaritalStatus(selectedOption)
                                }
                              />
                              {errors.maritalStatus && (
                                <span className="text-danger">
                                  {errors.maritalStatus}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Mobile number
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                value={mobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                              />
                              {errors.mobileNumber && (
                                <span className="text-danger">
                                  {errors.mobileNumber}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Whatsapp number
                            </label>
                            <div className="col-md-10">
                              <input
                                className="form-control"
                                value={whatsAppNumber}
                                onChange={(e) =>
                                  setWhatsAppNumber(e.target.value)
                                }
                              />
                              {errors.whatsAppNumber && (
                                <span className="text-danger">
                                  {errors.whatsAppNumber}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Religion
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={religionOptions}
                                placeholder="Select..."
                                styles={customStyles}
                                value={religion}
                                onChange={(selectedOption) =>
                                  setReligion(selectedOption)
                                }
                              />
                              {errors.religion && (
                                <span className="text-danger">
                                  {errors.religion}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Blood group
                            </label>
                            <div className="col-md-10">
                              <Select
                                options={bloodGroupOptions}
                                placeholder="Select..."
                                styles={customStyles}
                                value={bloodGroup}
                                onChange={(selectedOption) =>
                                  setBloodGroup(selectedOption)
                                }
                              />
                              {errors.bloodGroup && (
                                <span className="text-danger">
                                  {errors.bloodGroup}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Personal email
                            </label>
                            <div className="col-md-10">
                              <input
                                type="email"
                                className="form-control"
                                value={personalEmail}
                                onChange={(e) =>
                                  setPersonalEmail(e.target.value)
                                }
                              />
                              {errors.personalEmail && (
                                <span className="text-danger">
                                  {errors.personalEmail}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="input-block mb-3 row">
                            <label className="col-form-label col-md-2">
                              Effective Date
                            </label>
                            <div className="col-md-10">
                              <input
                                type="date"
                                className="form-control"
                                value={effectiveDate}
                                onChange={(e) =>
                                  setEffectiveDate(e.target.value)
                                }
                              />
                              {errors.effectiveDate && (
                                <span className="text-danger">
                                  {errors.effectiveDate}
                                </span>
                              )}
                            </div>
                          </div>
                        </form>

                        <div className="d-flex mt-3">
                          <Link
                            className="btn btn btn-primary next"
                            onClick={() => handleSave()}
                          >
                            Save
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployee;
