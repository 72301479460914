import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsProductsURL } from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";

function ViewDetailsModal({
  show,
  handleClose,
  updateProductInList, // Corrected prop name
  removeProductFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsProductsURL}${show}/`).then(
        (response) => {
          setFormData(response.data); // Ensure formData is set correctly
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start submitting
    axiosInstance.patch(`${LogisticsProductsURL}${show}/`, formData).then(
      (response) => {
        updateProductInList(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
        setIsSubmitting(false); // End submitting
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false); // End submitting on error
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${LogisticsProductsURL}${show}/`).then(
      () => {
        removeProductFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Product Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>
                      Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength={100}
                      minLength={1}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Loader className="feather-loader" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{formData.name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Created</th>
                        <td>{formData.created.slice(0, 10) || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Updated at</th>
                        <td>{formData.updated.slice(0, 10) || "N/A"}</td>
                      </tr>
                    </tbody>
                  </Table>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                    {/* <Button variant="danger" onClick={openDeleteConfirmation}>
                      Delete
                    </Button> */}
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this product?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
function AddCompanyModal({ show, handleClose, setInwardList }) {
  const [formData, setFormData] = useState({
    name: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start submitting
    axiosInstance.post(LogisticsProductsURL, formData).then(
      (response) => {
        setInwardList((prevList) => {
          console.log("Previous List:", prevList);
          return {
            ...prevList,
            count: prevList.count + 1,
            results: [response.data, ...prevList.results],
          };
        });
        toast.success("Successfully added");
        handleClose(); // Close modal and reset form
        setFormData({ name: "" }); // Reset form data
        setIsSubmitting(false); // End submitting
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false); // End submitting on error
        if (error?.response?.data?.name?.[0]) {
          toast.error(error?.response?.data?.name?.[0]);
        }
      }
    );
  };

  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Product</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={handleClose}
              disabled={isSubmitting}
            >
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? (
                <Loader className="feather-loader" />
              ) : (
                "Add Product"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

const Products = () => {
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [inwardList, setInwardList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const fetchProducts = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`${LogisticsProductsURL}`, {
        params: { page },
      });
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products: ", error);
      setIsLoading(false);
    }
  };

  const updateProductInList = (updatedProduct) => {
    setInwardList((prevList) => ({
      ...prevList,
      results: prevList?.results?.map((product) =>
        product.id === updatedProduct.id ? updatedProduct : product
      ),
    }));
  };

  const removeProductFromList = (id) => {
    setInwardList((prevList) => ({
      ...prevList,
      results: prevList?.results?.filter((product) => product.id !== id),
    }));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="card-title">Products List</h2>
            <Button
              onClick={handleAddModalShow}
              variant="primary"
              className="btn btn-primary btn-sm p-2 d-flex align-items-center"
            >
              <PlusCircle
                className="feather-download me-1"
                data-bs-toggle="tooltip"
                title="feather-download"
              />
              Add Product
            </Button>
          </div>
          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                inwardList?.results?.length > 0 ? (
                  inwardList?.results?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>{item.name || "Not available"}</td>
                      <td>{item.created.slice(0, 10) || "Not Available"}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1"
                          onClick={() => handleShow(item.id)}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="4">
                    {" "}
                    loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <ViewDetailsModal
            show={show}
            handleClose={handleClose}
            updateProductInList={updateProductInList} // Corrected prop name
            removeProductFromList={removeProductFromList}
          />
          <AddCompanyModal
            show={showAddModal}
            handleClose={handleAddModalClose}
            setInwardList={setInwardList}
          />
        </div>
      </div>
    </>
  );
};

export default Products;
