import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { axiosInstance } from "../../helpers/axios/axios";
import { useSelector } from "react-redux";
import { LogisticsSalesRegister } from "../../helpers/endpoints/api_endpoints";
import { Loader } from "react-feather";

const AddSalesModal = ({
  show,
  handleClose,
  outwardIds,
  updateOutwardInList,
  calculatedData,
}) => {
  const { userId } = useSelector((state) => state.account);
  const [billNumber, setBillNumber] = useState(null);
  const [billDate, setBillDate] = useState(new Date());

  const [loading, setLoading] = useState(false);
  const [roundedInvoice, setRoundedInvoice] = useState(null);

  useEffect(() => {
    if (calculatedData?.total_invoice) {
      setRoundedInvoice(Math.round(calculatedData.total_invoice));
    }
  }, [calculatedData]);

  const handleSubmit = () => {
    setLoading(true);
    if (!billNumber) {
      toast.error("Please enter a bill number");
      setLoading(false);
      return;
    }

    const data = {
      outwards: outwardIds, // Assuming outwardIds is an array
      user: userId,
      bill_number: billNumber,
      bill_date: billDate.toISOString().split("T")[0],
      total: calculatedData?.total
        ? Number(calculatedData.total).toFixed(2)
        : "0.00",
      total_invoice: calculatedData?.total_invoice
        ? Number(calculatedData.total_invoice).toFixed(2)
        : "0.00",
      is_done: false, // Based on the example JSON you provided
      pending_amount: JSON.stringify(roundedInvoice),
    };

    console.log(data); // For debugging purposes

    axiosInstance
      .post(`${LogisticsSalesRegister}`, data)
      .then((response) => {
        console.log(response.data);
        // Update the inwardList state in the parent component
        outwardIds.forEach((id) => {
          updateOutwardInList({ id, is_billed: true });
        });
        toast.success("Sales registered successfully!");
        setBillNumber(null); // Reset bill number after successful submission
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to register sales.");
        if (error.response?.data?.total_invoice) {
          toast.error(error?.response?.data?.total_invoice[0]);
        }
        setLoading(false);
      });
    setLoading(false);
  };

  const handleCloseModal = () => {
    setBillNumber(null); // Reset bill number when closing the modal
    handleClose();
    setLoading(false);
  };

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={handleCloseModal}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Sales</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">
                {" "}
                loading...{" "}
                <Loader
                  className="feather-loader"
                  data-bs-toggle="tooltip"
                  title="feather-loader"
                />
              </span>
            </Spinner>
          </div>
        ) : (
          <Form>
            <Form.Group controlId="formBillNumber">
              <Form.Label className=" mb-0">Bill Number</Form.Label>
              <Form.Control
                isInvalid={!billNumber}
                required
                type="input"
                value={billNumber || ""}
                onChange={(e) => setBillNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formBillDate">
              <Form.Label className="mt-2 mb-0">Bill Date</Form.Label>
              <DatePicker
                selected={billDate}
                onChange={(date) => setBillDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
              />
            </Form.Group>
            <Form.Group controlId="formRoundedInvoice">
              <Form.Label className=" mb-0 mt-3">
                Total Invoice After Round Off
              </Form.Label>
              <Form.Control
                required
                type="input"
                value={roundedInvoice || ""}
                onChange={(e) => setRoundedInvoice(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
        )}
        {calculatedData && (
          <div className="mt-3">
            <h5>Total Amount: {calculatedData?.total}</h5>
            <h5>Total Invoice: {calculatedData?.total_invoice}</h5>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSalesModal;
