import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  basicUserDetails,
  documentList,
  namingList,
} from "../../../../../helpers/endpoints/api_endpoints";

import { Button, Modal } from "react-bootstrap";
import { Loader } from "react-feather";

const PaySlipReportTable = () => {
  const [show, setShow] = useState(false);
  const [documentLists, setDocumentLists] = useState();
  const [users, setUsers] = useState({});
  console.log(documentLists);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    axiosInstance
      .get(`${documentList}`)
      .then((response) => {
        console.log(response.data);
        setDocumentLists(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      if (!documentLists) return; // Add this check to avoid calling map on undefined
      const userIds = documentLists.map((doc) => doc.uploaded_by);
      const uniqueUserIds = [...new Set(userIds)]; // Remove duplicates

      uniqueUserIds.forEach(async (userId) => {
        try {
          const response = await axiosInstance.get(
            `${basicUserDetails}${userId}/`
          );
          setUsers((prevUsers) => ({
            ...prevUsers,
            [userId]: response.data,
          }));
        } catch (error) {
          console.log(error);
        }
      });
    };

    fetchUsers();
  }, [documentLists]);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "File Name",
      dataIndex: "file_name",
      render: (text, record) => (
        <div className="table-avatar">
          {/* <Link to="/profile" className="avatar">
            <img alt="" src={record.image} />
          </Link>
          <Link to="/profile">
            {text} <span>{record.role}</span>
          </Link> */}
          {text}
        </div>
      ),
    },
    {
      title: "Finalized Name",
      dataIndex: "finalized_name",
      render: (text, record) => (
        console.log(record, text),
        (
          <div className="table-avatar">
            {record?.finalized_name ? (
              record?.finalized_name
            ) : (
              <>no name available </>
            )}
          </div>
        )
      ),
    },

    {
      title: "Is Finalize",
      dataIndex: "is_finalized",
      render: (record) => (
        <div className="table-avatar">
          {record?.is_finalized ? "Yes" : "No"}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (record) => (
        <div className="table-avatar">{record.slice(0, 10)}</div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "uploaded_by",
      render: (record) => {
        const user = users[record];

        return user ? (
          <div className="table-avatar">{user?.employee_name}</div> // Adjust this according to your user object structure
        ) : (
          <div className="table-avatar">
            {" "}
            loading...{" "}
            <Loader
              className="feather-loader"
              data-bs-toggle="tooltip"
              title="feather-loader"
            />
          </div>
        );
      },
    },
    {
      title: "Actions",

      render: (record) => (
        <>
          <Link to={record.uploaded_file} className="btn btn-sm btn-primary">
            <i className="fa fa-download " /> Download File
          </Link>

          <button
            onClick={() => setShow(true)}
            className="btn btn-sm btn-primary ms-1"
          >
            View File
          </button>

          <Modal
            size="lg"
            show={show}
            onHide={() => setShow(false)}
            dialogClassName="modal-90w modal-fullscreen"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header>
              <Modal.Title id="example-custom-modal-styling-title">
                View Document
              </Modal.Title>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Header>
            <Modal.Body>
              {record.uploaded_file ? (
                <iframe
                  title="sss"
                  src={record.uploaded_file}
                  width="100%"
                  height="600px"
                />
              ) : (
                "No data available"
              )}
            </Modal.Body>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal>
        </>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default PaySlipReportTable;
