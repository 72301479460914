import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown, Form } from "react-bootstrap";

import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
} from "../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../helpers/axios/axios";

const ExportModal = ({ show, handleClose, handleExport }) => {
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchClients();
    fetchCompanies();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      console.log(response.data);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  const handleExportClick = () => {
    handleExport({ selectedClient, selectedCompany, startDate, endDate });
    handleClose();
  };
  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setEndDate("");
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select Filters for Export</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title> (optional)</Modal.Title>
        <Dropdown onSelect={(e) => setSelectedClient(e)} className="mb-2">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {clients?.results?.find(
              (client) => client.id === parseInt(selectedClient)
            )?.name || "Select Client"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {clients?.results?.map((client) => (
              <Dropdown.Item key={client.id} eventKey={client.id}>
                {client.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown onSelect={(e) => setSelectedCompany(e)} className="mb-2">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {companies?.results?.find(
              (company) => company.id === parseInt(selectedCompany)
            )?.name || "Select Company"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {companies?.results?.map((company) => (
              <Dropdown.Item key={company.id} eventKey={company.id}>
                {company.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Form.Control
          className="mb-2"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          placeholder="Start Date"
        />

        <Form.Control
          className="mb-2"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          placeholder="End Date"
        />
        <Button className="me-2" variant="secondary" onClick={handleClose}>
          Close
        </Button>

        <Button variant="secondary" onClick={handleResetFilters}>
          Reset Filters
        </Button>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleExportClick}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportModal;
