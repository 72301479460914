import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  LogisticsPaymentRegister,
  LogisticsPendingPayment,
  LogisticsSalesRegister,
  LogisticsUserUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

function AddCompanyModal({
  showAddModal,
  handleClose,
  salesId,
  setCompanyList,
  fetchUpdatedData,
}) {
  const { userId } = useSelector((state) => state.account);
  const [billDate, setBillDate] = useState(new Date());
  const [pendingPayment, setPendingPayment] = useState("");

  const paymentTypes = ["TDS", "Receipt", "Round off"];

  const [formData, setFormData] = useState({
    user: userId,
    sales: salesId,
    payment_type: "",
    received_amount: "",
    received_date: billDate.toISOString().split("T")[0],
    remarks: "",
    utr_detail: "",
  });

  // Update formData when billDate changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      received_date: billDate.toISOString().split("T")[0],
    }));
  }, [billDate]);

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, sales: salesId }));
  }, [salesId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value);

    // Prevent negative numbers
    if (name === "received_amount" && parsedValue < 0) {
      e.target.value = 0; // Reset the input field to 0
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: 0,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: parsedValue >= 0 ? parsedValue : value, // Ensure valid parsing
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axiosInstance.post(LogisticsPaymentRegister, formData).then(
      (response) => {
        console.log(response.data);
        toast.success("Payment Updated Successfully");
        handleClose();
        setBillDate(new Date());
        fetchUpdatedData(); // Call the function to fetch updated data
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    axiosInstance.get(`${LogisticsPendingPayment}${salesId}/`).then(
      (response) => {
        setPendingPayment(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [salesId]);

  const handleCloseModal = () => {
    setBillDate(new Date());
    handleClose();
  };

  return (
    <Modal centered size="lg" show={showAddModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Label className="mb-2">
            <b> Pending amount :</b>{" "}
            {pendingPayment?.pending_amount
              ? pendingPayment?.pending_amount
              : "NA"}
          </Form.Label>

          <Form.Group className="mb-3" controlId="formPaymentType">
            <Form.Label className="mb-0">
              Payment Type<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              name="payment_type"
              onChange={handleChange}
              required
            >
              <option value="">Select payment type</option>
              {paymentTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formReceivedAmount">
            <Form.Label className="mb-0">
              Received amount<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="number"
              min="0"
              onWheel={(e) => e.target.blur()} // Prevent change on scroll
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }} // Prevent change on arrow keys
              step="any"
              name="received_amount"
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formRemarks">
            <Form.Label className="mt-2 mb-0">Remarks</Form.Label>
            <Form.Control type="text" name="remarks" onChange={handleChange} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="forUtr_detail">
            <Form.Label className="mt-2  mb-0">utr_detail</Form.Label>
            <Form.Control
              type="text"
              name="utr_detail"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group controlId="formDate">
            <Form.Label className="mt-2 mb-0">
              Date<span className="text-danger">*</span>
            </Form.Label>

            <DatePicker
              selected={billDate}
              onChange={(date) => setBillDate(date)}
              dateFormat="yyyy-MM-dd"
              className="form-control"
            />
          </Form.Group>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
const AddPayment = () => {
  const [salesId, setSalesId] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mappedUsers, setMappedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  console.log(mappedUsers);
  // Filters
  const [billNumber, setBillNumber] = useState("");
  const [billDate, setBillDate] = useState(null);
  const [isDone, setIsDone] = useState("");
  const [created, setCreated] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    fetchClients();
    fetchCompanies();
    fetchUsers(currentPage);
  }, [currentPage]);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };

  useEffect(() => {
    axiosInstance.get(LogisticsUserUrl).then(
      (response) => {
        setDesignationData(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const fetchUsers = (page) => {
    setLoading(true);
    console.log(selectedClient, selectedCompany);
    const filters = {
      bill_number: billNumber,
      bill_date: billDate ? billDate.toISOString().split("T")[0] : "",
      is_done: isDone,
      created: created ? created.toISOString().split("T")[0] : "",
      start_date: startDate ? startDate.toISOString().split("T")[0] : "",
      end_date: endDate ? endDate.toISOString().split("T")[0] : "",
      client: selectedClient?.id,
      company: selectedCompany?.id,
    };

    axiosInstance
      .get(LogisticsSalesRegister, { params: { ...filters, page } })
      .then(
        (response) => {
          console.log(response.data);
          setCompaniesList(response.data);
          setLoading(false);
          setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 10 items per page
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
  };

  useEffect(() => {
    if (designationData.results && companiesList.results) {
      setMappedUsers(mapUsersToDesignations(companiesList, designationData));
    }
  }, [designationData, companiesList]);

  function mapUsersToDesignations(users, designations) {
    const designationLookup = designations.results?.reduce(
      (acc, designation) => {
        acc[designation.id] = designation.name;
        return acc;
      },
      {}
    );

    return users?.results?.map((user) => {
      return {
        ...user,
        UserName: user.user ? designationLookup[user.user] : null,
      };
    });
  }

  const updateCompanyInList = (updatedCompany) => {
    setCompaniesList((prevList) =>
      prevList?.results?.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      )
    );
  };

  const removeCompanyFromList = (id) => {
    setCompaniesList((prevList) =>
      prevList?.results?.filter((company) => company.id !== id)
    );
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const resetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setBillNumber("");
    setBillDate(null);
    setIsDone("");
    setCreated(null);
    setCurrentPage(1);
    setStartDate(null);
    setEndDate(null);
  };
  useEffect(() => {
    fetchUsers(currentPage);
  }, [
    billNumber,
    billDate,
    isDone,
    created,
    currentPage,
    startDate,
    endDate,
    selectedClient?.id,
    selectedCompany?.id,
  ]);

  const renderUniqueNames = (details, key) => {
    const uniqueNames = new Set();
    details.forEach((e) => {
      if (e[key]?.name) {
        uniqueNames.add(e[key]?.name);
      }
    });
    return Array.from(uniqueNames).map((name, index) => (
      <li key={index} className="text-start">
        {name}
      </li>
    ));
  };
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h2 className="card-title">Add Payment</h2>
          </div>
          <div className="d-flex mb-2">
            <Dropdown
              onSelect={(e) =>
                setSelectedClient({
                  id: e,
                  name:
                    clients?.results?.find(
                      (client) => client.id === parseInt(e)
                    )?.name || "Select Client",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedClient.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clients?.results?.map((client) => (
                  <Dropdown.Item key={client.id} eventKey={client.id}>
                    {client.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="mx-2"
              onSelect={(e) =>
                setSelectedCompany({
                  id: e,
                  name:
                    companies?.results?.find(
                      (company) => company.id === parseInt(e)
                    )?.name || "Select Company",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedCompany.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {companies?.results?.map((company) => (
                  <Dropdown.Item key={company.id} eventKey={company.id}>
                    {company.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Form.Control
              type="text"
              value={billNumber}
              onChange={(e) => setBillNumber(e.target.value)}
              placeholder="Bill Number"
              className="me-2"
            />

            <Form.Control
              as="select"
              value={isDone}
              onChange={(e) => setIsDone(e.target.value)}
              className="me-2 ms-2"
            >
              <option value="">Payment Status</option>
              <option value="true">Payment Added</option>
              <option value="false">Payment Pending</option>
            </Form.Control>

            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd"
              className="form-control me-2"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd"
              className="form-control me-2"
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <Button
              variant="primary"
              onClick={() => fetchUsers(1)}
              className="me-2"
            >
              Apply Filters
            </Button>
            <Button variant="secondary" onClick={resetFilters}>
              Reset Filters
            </Button>
          </div>

          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Client</th>
                <th>Company</th>
                <th>Pending Amount</th>
                <th>Bill Number</th>
                <th>Bill Date</th>
                <th>Total</th>
                <th>Total Invoice</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="9">
                    Loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              ) : mappedUsers?.length > 0 ? (
                mappedUsers.map((item, index) => (
                  <tr key={item.id}>
                    <td>{(currentPage - 1) * 10 + index + 1}</td>
                    <td>
                      {item.outwards_details.length > 0
                        ? renderUniqueNames(
                            item.outwards_details,
                            "outward_client_name"
                          )
                        : "Not available"}
                    </td>
                    <td>
                      {item.outwards_details.length > 0
                        ? renderUniqueNames(
                            item.outwards_details,
                            "outward_company_name"
                          )
                        : "Not available"}
                    </td>
                    <td>{item.pending_amount || "Not available"}</td>
                    <td>{item.bill_number || "Not available"}</td>
                    <td>{item.bill_date || "Not available"}</td>
                    <td>{item.total || "Not available"}</td>
                    <td>{item.total_invoice || "Not available"}</td>
                    <td>
                      {item.is_done === false ? (
                        <Button
                          onClick={() => {
                            setSalesId(item.id);
                            setShowAddModal(true);
                          }}
                          variant="primary"
                          className="btn btn-primary btn-sm p-2"
                        >
                          Add Payment
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-primary btn-sm p-2"
                          variant="success"
                        >
                          Payment Added
                        </Button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9">No data</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <AddCompanyModal
            salesId={salesId}
            showAddModal={showAddModal}
            handleClose={() => setShowAddModal(false)}
            setCompanyList={setCompaniesList}
            fetchUpdatedData={() => fetchUsers(currentPage)}
          />
        </div>
      </div>
    </>
  );
};

export default AddPayment;
