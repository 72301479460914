import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  inwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Link } from "react-router-dom";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Select } from "antd";
import { toast } from "react-toastify";
import { Loader } from "react-feather";

function ViewDetailsModal({
  show,
  handleClose,
  updateOutwardInList,
  removeOutwardFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [productList, setProductList] = useState([]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${inwardFormUrl}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Save original data
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch products data
      axiosInstance.get(LogisticsCompaniesURL).then(
        (response) => {
          setProductList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );

      // Fetch client data
      axiosInstance.get(LogisticsClientsURL).then(
        (response) => {
          setClientList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
      setOriginalData(null); // Reset original data
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "client" ? value : label && name === "company" ? value : label;
    console.log(selectedValue);
    setFormData({ ...formData, [name]: selectedValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const changedData = {};
    for (let key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    console.log("Changed Data: ", changedData);

    axiosInstance.patch(`${inwardFormUrl}${show}/`, changedData).then(
      (response) => {
        console.log(response.data);
        updateOutwardInList(response.data);
        setIsEditing(false);
        handleClose();
        toast.success("Saved successfully");
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${inwardFormUrl}${show}/`).then(
      () => {
        removeOutwardFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  const ClientList = clientList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));

  const CompanyList = productList?.results?.map((company) => ({
    value: company.id,
    label: company?.name,
  }));
  const transportType = [
    { value: "1", label: "Air" },
    { value: "2", label: "Shipping" },
    { value: "3", label: "Surface" },
  ];
  const typeDropdownData = [
    { value: "1", label: "ATM Movement" },
    { value: "2", label: "Household" },
    { value: "3", label: "3PL" },
    { value: "4", label: "Other" },
  ];
  const ATMCategory = [
    { value: "1", label: "Primary" },
    { value: "2", label: "Secondary" },
  ];
  const ATMType = [
    { value: "1", label: "Pickup" },
    { value: "2", label: "Buyback" },
    { value: "3", label: "Shifting" },
    { value: "4", label: "Others" },
  ];
  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Outward Form Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formType">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">Type</label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={typeDropdownData}
                          placeholder="Select Type"
                          onChange={(value, option) =>
                            handleSelectChange("type", value, option.label)
                          }
                          required
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formClient">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">Client</label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={ClientList}
                          placeholder="Select Client Name"
                          onChange={(value, option) =>
                            handleSelectChange("client", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formCompany">
                    <div className="input-block mb-3 row align-items-center">
                      <label className="col-lg-3 col-form-label">
                        Company Name
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={CompanyList}
                          placeholder="Select Company Name"
                          onChange={(value, option) =>
                            handleSelectChange("company", value, option.label)
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="formATMCategory">
                    {formData?.type === "ATM Movement" && (
                      <div className="input-block mb-3 row align-items-center">
                        <label className="col-lg-3 col-form-label">
                          ATM Category
                        </label>
                        <div className="col-lg-8">
                          <Select
                            className="form-control"
                            options={ATMCategory}
                            placeholder="Select ATM Category"
                            onChange={(value, option) =>
                              handleSelectChange(
                                "atm_category",
                                value,
                                option.label
                              )
                            }
                          />
                        </div>
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group controlId="formATMType">
                    {formData?.type === "ATM Movement" &&
                      formData?.atm_category === "Secondary" && (
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            ATM Type
                          </label>
                          <div className="col-lg-8">
                            <Select
                              className="form-control"
                              options={ATMType}
                              placeholder="Select ATM Type"
                              onChange={(value, option) =>
                                handleSelectChange(
                                  "secondary_type",
                                  value,
                                  option.label
                                )
                              }
                            />
                          </div>
                        </div>
                      )}
                  </Form.Group>
                  <Form.Group controlId="formTransportType">
                    <div className="input-block mb-1 mt-2 row align-items-center">
                      <label className="col-lg-3 col-form-label">
                        Transport Type
                      </label>
                      <div className="col-lg-8">
                        <Select
                          className="form-control"
                          options={transportType}
                          placeholder="Select Transport Type"
                          onChange={(value, option) =>
                            handleSelectChange(
                              "transport_type",
                              value,
                              option.label
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMID">
                      <Form.Label>ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="atm_id"
                        value={formData.atm_id}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label>ATM Serial</Form.Label>
                      <Form.Control
                        type="text"
                        name="atm_sno"
                        value={formData.atm_sno}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label>From ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="from_atm"
                        value={formData.from_atm}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  {formData?.type === "ATM Movement" && (
                    <Form.Group controlId="formATMSerial">
                      <Form.Label>To ATM ID</Form.Label>
                      <Form.Control
                        type="text"
                        name="to_atm"
                        value={formData.to_atm}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId="formDocketNumber">
                    <Form.Label>Docket Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_docket_number"
                      value={formData.client_docket_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceNumber">
                    <Form.Label>Invoice Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_number"
                      value={formData.client_invoice_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formInvoiceValue">
                    <Form.Label>Invoice Value</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_invoice_value"
                      value={formData.client_invoice_value}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEwayBill">
                    <Form.Label>E-Way Bill</Form.Label>
                    <Form.Control
                      type="text"
                      name="eway_bill"
                      value={formData.eway_bill}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromAddress">
                    <Form.Label>From Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_address"
                      value={formData.from_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromPincode">
                    <Form.Label>From Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_pincode"
                      value={formData.from_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromContact">
                    <Form.Label>From Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="from_contact"
                      value={formData.from_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFromEmail">
                    <Form.Label>From Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="from_email"
                      value={formData.from_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToAddress">
                    <Form.Label>To Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_address"
                      value={formData.to_address}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToPincode">
                    <Form.Label>To Pincode</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_pincode"
                      value={formData.to_pincode}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToContact">
                    <Form.Label>To Contact</Form.Label>
                    <Form.Control
                      type="text"
                      name="to_contact"
                      value={formData.to_contact}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formToEmail">
                    <Form.Label>To Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="to_email"
                      value={formData.to_email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formReceiver">
                    <Form.Label>Receiver</Form.Label>
                    <Form.Control
                      type="text"
                      name="receiver"
                      value={formData.receiver}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSecondaryType">
                    <Form.Label>Secondary Type</Form.Label>
                    <Form.Control
                      type="text"
                      name="secondary_type"
                      value={formData.secondary_type}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formSubclientName">
                    <Form.Label>Subclient Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="subclient_name"
                      value={formData.subclient_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formRemarks">
                    <Form.Label>Remarks</Form.Label>
                    <Form.Control
                      type="text"
                      name="remarks"
                      value={formData.remarks}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  {/* {formData.items && formData.items.length > 0 && (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Product Name</th>
                          <th>Weight</th>
                          <th>Pieces</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.items.map((product) => (
                          <tr key={product.id}>
                            <td>{product?.name?.name || "N/A"}</td>
                            <td>{product.weight || "N/A"}</td>
                            <td>{product.pieces || "N/A"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )} */}

                  {/* <Button className="mt-3" variant="primary" type="submit">
                    Save Changes
                  </Button> */}
                </Form>
              ) : (
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <td>{formData.type ? formData.type : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Client</th>
                      <td>
                        {formData.client_details?.name
                          ? formData.client_details?.name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Company</th>
                      <td>
                        {formData.company_details?.name
                          ? formData.company_details?.name
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Docket Number</th>
                      <td>
                        {formData.client_docket_number
                          ? formData.client_docket_number
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice Number</th>
                      <td>
                        {formData.client_invoice_number
                          ? formData.client_invoice_number
                          : "N/A"}
                      </td>
                    </tr>
                    <tr>
                      <th>Invoice Value</th>
                      <td>
                        {formData.client_invoice_value
                          ? formData.client_invoice_value
                          : "NA"}
                      </td>
                    </tr>
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM Category</th>
                        <td>
                          {formData.atm_category ? formData.atm_category : "NA"}
                        </td>
                      </tr>
                    )}

                    {formData?.type === "ATM Movement" &&
                      formData?.atm_category === "Secondary" && (
                        <tr>
                          <th>Secondary Type</th>
                          <td>
                            {formData.secondary_type
                              ? formData.secondary_type
                              : "NA"}
                          </td>
                        </tr>
                      )}

                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM ID</th>
                        <td>{formData.atm_id ? formData.atm_id : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>ATM S.No</th>
                        <td>{formData.atm_sno ? formData.atm_sno : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>To ATM ID</th>
                        <td>{formData.to_atm ? formData.to_atm : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>From ATM ID</th>
                        <td>{formData.from_atm ? formData.from_atm : "NA"}</td>
                      </tr>
                    )}
                    {formData?.type === "ATM Movement" && (
                      <tr>
                        <th>E-Way Bill</th>
                        <td>
                          {formData.eway_bill ? formData.eway_bill : "NA"}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>From Address</th>
                      <td>
                        {formData.from_address ? formData.from_address : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Pincode</th>
                      <td>
                        {formData.from_pincode ? formData.from_pincode : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Contact</th>
                      <td>
                        {formData.from_contact ? formData.from_contact : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>From Email</th>
                      <td>
                        {formData.from_email ? formData.from_email : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Address</th>
                      <td>
                        {formData.to_address ? formData.to_address : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Pincode</th>
                      <td>
                        {formData.to_pincode ? formData.to_pincode : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Contact</th>
                      <td>
                        {formData.to_contact ? formData.to_contact : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>To Email</th>
                      <td>{formData.to_email ? formData.to_email : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Receiver</th>
                      <td>{formData.receiver ? formData.receiver : "NA"}</td>
                    </tr>

                    <tr>
                      <th>Subclient Name</th>
                      <td>
                        {formData.subclient_name
                          ? formData.subclient_name
                          : "NA"}
                      </td>
                    </tr>
                    <tr>
                      <th>Transport Type</th>
                      <td>
                        {formData.transport_type
                          ? formData.transport_type
                          : "NA"}
                      </td>
                    </tr>

                    <tr>
                      <th>Remarks</th>
                      <td>{formData.remarks ? formData.remarks : "NA"}</td>
                    </tr>
                    <tr>
                      <th>Products</th>
                      <td>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Pieces</th>
                              <th>Weight</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.items?.map((product, index) => (
                              <tr key={index}>
                                <td>{product?.name?.name}</td>
                                <td>{product.number}</td>
                                <td>{product.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              )}
              <div className="d-flex justify-content-end mt-2">
                <Button
                  variant="secondary"
                  onClick={handleClose}
                  className="me-2"
                >
                  Close
                </Button>
                {formData.is_completed === false && isEditing ? (
                  <Button variant="success" onClick={handleSubmit}>
                    Save
                  </Button>
                ) : (
                  formData.is_completed === false && (
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                  )
                )}
                {/* {formData.is_completed === false && (
                  <Button
                    variant="danger"
                    onClick={openDeleteConfirmation}
                    className="ms-2"
                  >
                    Delete
                  </Button>
                )} */}
              </div>
            </>
          ) : (
            <p>
              {" "}
              loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
              ...
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this outward form?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          {/* <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const DashboardInwardTable = () => {
  const [show, setShow] = useState(false);
  const [inwardList, setInwardList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  // fetch products data
  useEffect(() => {
    axiosInstance.get(`${inwardFormUrl}`).then(
      (response) => {
        console.log(response.data);
        setInwardList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  const updateOutwardInList = (updatedOutward) => {
    setInwardList((prevList) => {
      console.log("Previous List:", prevList);
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedOutward.id ? updatedOutward : outward
        ),
      };
    });
  };

  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">Inward</h3>
        </div>
        <div className="px-2">
          <div className="table-responsive">
            <Table bordered hover className="text-center">
              <thead className="table-secondary">
                <tr>
                  <th>No.</th>
                  <th>Type</th>
                  <th> Docket Number</th>
                  <th> ATM ID</th>
                  <th> Date</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {inwardList.count > 0 ? (
                  inwardList?.results?.slice(0, 5).map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.type ? item.type : "Not available"}</td>
                      <td>
                        {item?.client_docket_number
                          ? item.client_docket_number
                          : "Not Available"}
                      </td>
                      <td>{item?.atm_id ? item?.atm_id : "Not Available"}</td>
                      <td>
                        {item?.updated_at
                          ? item?.updated_at?.slice(0, 10)
                          : "Not Available"}
                      </td>
                      {/* <td>
                          {item.is_completed === true ? (
                            <div
                              variant="success"
                              className="btn btn-success btn-sm ms-1"
                            >
                              Outward done
                            </div>
                          ) : (
                            <Link
                              to={`/outward-form/${item.id}`}
                              variant="primary"
                              className="btn btn-primary btn-sm ms-1"
                            >
                              Add Outward
                            </Link>
                          )}
                          <Button
                            className="btn btn-primary btn-sm ms-1"
                            variant="primary"
                            onClick={() => handleShow(item.id)}
                          >
                            View Details
                          </Button>
                        </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">
                      {" "}
                      loading...{" "}
                      <Loader
                        className="feather-loader"
                        data-bs-toggle="tooltip"
                        title="feather-loader"
                      />
                      ...
                    </td>
                  </tr>
                )}
                <ViewDetailsModal
                  updateOutwardInList={updateOutwardInList}
                  show={show}
                  handleClose={handleClose}
                />
              </tbody>
            </Table>
          </div>
        </div>
        <div className="card-footer">
          <Link to="/inward-form-list">View all Inward</Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardInwardTable;
