export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "OutWard List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/outward-list",
        icon: "la la-file-text",
      },
      {
        menuValue: "Pending Approvals",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/approve-outward",
        icon: "la la-file-text",
      },
      {
        menuValue: "Inward Form",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-form",
        icon: "la la-file-text",
      },
      {
        menuValue: "Inward List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/inward-form-list",
        icon: "la la-file-text",
      },

      {
        menuValue: "Companies",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-companies",
        icon: "la la-briefcase",
      },
      {
        menuValue: "Products",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-products",
        icon: "la la-cube",
      },
      {
        menuValue: "Clients",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/logistics-clients",
        icon: "la la-user",
      },
      {
        menuValue: "Users",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/users",
        icon: "la la-user",
      },
      {
        menuValue: "Add Payment",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/add-payment",
        icon: "la la-credit-card",
      },
      {
        menuValue: "Payments List",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/payment-list",
        icon: "la la-list",
      },

      {
        menuValue: "Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-chart-bar",
        subMenus: [
          {
            menuValue: "Stock Report",
            route: "/stock-report",
          },
          {
            menuValue: "Outstanding Report",
            route: "/outstanding-report",
          },
        ],
      },
    ],
  },
];
