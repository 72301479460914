import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import { axiosInstance } from "../../helpers/axios/axios";
import { LogisticsCompaniesURL } from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";

// function ViewDetailsModal({
//   show,
//   handleClose,
//   updateCompanyInList,
//   removeCompanyFromList,
// }) {
//   const [formData, setFormData] = useState(null);
//   const [isEditing, setIsEditing] = useState(false);
//   const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null); // New state for file

//   useEffect(() => {
//     if (show) {
//       axiosInstance.get(`${LogisticsCompaniesURL}${show}/`).then(
//         (response) => {
//           console.log(response.data);
//           setFormData(response.data);
//         },
//         (error) => {
//           console.log(error);
//         }
//       );
//     } else {
//       setIsEditing(false);
//       setFormData(null);
//     }
//   }, [show]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Allow only numbers and '+' character for the contact number field
//     if (name === "contact_number") {
//       const regex = /^[0-9+]*$/;
//       if (!regex.test(value)) {
//         return; // Stop further processing if input is invalid
//       }
//     }

//     setFormData({ ...formData, [name]: value });
//   };

//   const handleFileChange = (e) => {
//     setSelectedFile(e.target.files[0]);
//   };

//   const handleEditToggle = () => {
//     setIsEditing(!isEditing);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     const formDataToSend = new FormData();

//     for (const key in formData) {
//       formDataToSend.append(key, formData[key]);
//     }

//     if (selectedFile) {
//       formDataToSend.append("logo", selectedFile);
//     }

//     axiosInstance
//       .patch(`${LogisticsCompaniesURL}${show}/`, formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       })
//       .then(
//         (response) => {
//           console.log(response.data);
//           updateCompanyInList(response.data);
//           setIsSubmitting(false);
//           setIsEditing(false);
//           handleClose();
//           toast.success("Saved successfully");
//         },
//         (error) => {
//           console.log(error);
//           setIsSubmitting(false);

//           const gstErrorMsg = error?.response?.data?.gst_number?.[0];
//           const logoErrorMsg = error?.response?.data?.logo?.[0];

//           if (gstErrorMsg) {
//             toast.error(gstErrorMsg);
//           }

//           if (logoErrorMsg) {
//             toast.error(logoErrorMsg);
//           }
//         }
//       );
//   };

//   const handleDelete = () => {
//     axiosInstance.delete(`${LogisticsCompaniesURL}${show}/`).then(
//       () => {
//         removeCompanyFromList(show);
//         setShowDeleteConfirmation(false);
//         handleClose();
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
//   };

//   const openDeleteConfirmation = () => {
//     setShowDeleteConfirmation(true);
//   };

//   const closeDeleteConfirmation = () => {
//     setShowDeleteConfirmation(false);
//   };
//   return (
//     <>
//       <Modal
//         centered
//         size="lg"
//         show={show}
//         onHide={handleClose}
//         aria-labelledby="contained-modal-title-vcenter"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Company Details
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           {formData ? (
//             <>
//               {isEditing ? (
//                 <Form onSubmit={handleSubmit}>
//                   <Form.Group controlId="formName">
//                     <Form.Label className="mb-0 mt-2">
//                       Name<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleChange}
//                       required
//                       maxLength={100}
//                       minLength={1}
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formContactName">
//                     <Form.Label className="mb-0 mt-2">
//                       Contact Name<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="contact_name"
//                       value={formData.contact_name}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formAddress">
//                     <Form.Label className="mb-0 mt-2">
//                       Address<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       required
//                       type="text"
//                       name="address"
//                       value={formData.address}
//                       onChange={handleChange}
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formContactNumber">
//                     <Form.Label className="mb-0 mt-2">
//                       Contact Number<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       name="contact_number"
//                       value={formData.contact_number}
//                       onChange={handleChange}
//                       onInput={(e) => {
//                         e.target.value = e.target.value.replace(/[^0-9+]/g, ""); // Remove any disallowed characters
//                       }}
//                       required
//                     />
//                   </Form.Group>

//                   <Form.Group controlId="formGstNumber">
//                     <Form.Label className="mb-0 mt-2">
//                       GST Number<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="gst_number"
//                       value={formData.gst_number}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formPrefix">
//                     <Form.Label className="mb-0 mt-2">
//                       Prefix<span className="text-danger">*</span>
//                     </Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="prefix"
//                       value={formData.prefix}
//                       onChange={handleChange}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formLogo">
//                     <Form.Label className="mb-0 mt-2">Logo</Form.Label>
//                     <Form.Control
//                       type="file"
//                       name="logo"
//                       onChange={handleFileChange}
//                     />
//                   </Form.Group>
//                   <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                       Cancel
//                     </Button>
//                     <Button
//                       type="submit"
//                       variant="primary"
//                       disabled={isSubmitting}
//                     >
//                       {isSubmitting ? "Submitting..." : "Save"}
//                     </Button>
//                   </Modal.Footer>
//                 </Form>
//               ) : (
//                 <>
//                   <Table striped bordered hover>
//                     <tbody>
//                       <tr>
//                         <th>Name</th>
//                         <td>{formData.name || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Contact Name</th>
//                         <td>{formData.contact_name || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Address</th>
//                         <td>{formData.address || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Contact Number</th>
//                         <td>{formData.contact_number || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>GST Number</th>
//                         <td>{formData.gst_number || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Prefix</th>
//                         <td>{formData.prefix || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Created</th>
//                         <td>{formData.created.slice(0, 10) || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Updated at</th>
//                         <td>{formData.updated.slice(0, 10) || "N/A"}</td>
//                       </tr>
//                       <tr>
//                         <th>Logo</th>
//                         <td>
//                           <img
//                             src={formData.logo}
//                             alt="Company Logo"
//                             style={{ width: "100px", height: "auto" }}
//                           />
//                         </td>
//                       </tr>
//                     </tbody>
//                   </Table>
//                   <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                       Close
//                     </Button>
//                     <Button variant="primary" onClick={handleEditToggle}>
//                       Edit
//                     </Button>
//                     {/* <Button variant="danger" onClick={openDeleteConfirmation}>
//                       Delete
//                     </Button> */}
//                   </Modal.Footer>
//                 </>
//               )}
//             </>
//           ) : (
//             <p>
//               {" "}
//               loading...{" "}
//               <Loader
//                 className="feather-loader"
//                 data-bs-toggle="tooltip"
//                 title="feather-loader"
//               />
//             </p>
//           )}
//         </Modal.Body>
//       </Modal>

//       <Modal
//         show={showDeleteConfirmation}
//         onHide={closeDeleteConfirmation}
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Delete</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={closeDeleteConfirmation}>
//             Cancel
//           </Button>
//           {/* <Button variant="danger" onClick={handleDelete}>
//             Delete
//           </Button> */}
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
function ViewDetailsModal({
  show,
  handleClose,
  updateCompanyInList,
  removeCompanyFromList,
}) {
  const [formData, setFormData] = useState(null);
  const [originalData, setOriginalData] = useState(null); // New state for original data
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // New state for file

  useEffect(() => {
    if (show) {
      axiosInstance.get(`${LogisticsCompaniesURL}${show}/`).then(
        (response) => {
          console.log(response.data);
          setFormData(response.data);
          setOriginalData(response.data); // Store the original data
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setIsEditing(false);
      setFormData(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const getChangedData = () => {
    const changedData = {};

    for (const key in formData) {
      if (formData[key] !== originalData[key]) {
        changedData[key] = formData[key];
      }
    }

    return changedData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formDataToSend = new FormData();
    const changedData = getChangedData();

    for (const key in changedData) {
      formDataToSend.append(key, changedData[key]);
    }

    if (selectedFile) {
      formDataToSend.append("logo", selectedFile);
    }

    axiosInstance
      .patch(`${LogisticsCompaniesURL}${show}/`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(
        (response) => {
          console.log(response.data);
          updateCompanyInList(response.data);
          setIsSubmitting(false);
          setIsEditing(false);
          handleClose();
          toast.success("Saved successfully");
        },
        (error) => {
          console.log(error);
          setIsSubmitting(false);

          const gstErrorMsg = error?.response?.data?.gst_number?.[0];
          const logoErrorMsg = error?.response?.data?.logo?.[0];

          if (gstErrorMsg) {
            toast.error(gstErrorMsg);
          }

          if (logoErrorMsg) {
            toast.error(logoErrorMsg);
          }
        }
      );
  };

  const handleDelete = () => {
    axiosInstance.delete(`${LogisticsCompaniesURL}${show}/`).then(
      () => {
        removeCompanyFromList(show);
        setShowDeleteConfirmation(false);
        handleClose();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const closeDeleteConfirmation = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <>
      <Modal
        centered
        size="lg"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Company Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formData ? (
            <>
              {isEditing ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>
                      Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      maxLength={100}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactName">
                    <Form.Label>
                      Contact Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_name"
                      value={formData.contact_name}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formAddress">
                    <Form.Label>
                      Address<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formContactNumber">
                    <Form.Label>
                      Contact Number<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="contact_number"
                      value={formData.contact_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formGstNumber">
                    <Form.Label>
                      GST Number<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="gst_number"
                      value={formData.gst_number}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPrefix">
                    <Form.Label>
                      Prefix<span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="prefix"
                      value={formData.prefix}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formLogo">
                    <Form.Label>Logo</Form.Label>
                    <Form.Control
                      type="file"
                      name="logo"
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Submitting..." : "Save"}
                    </Button>
                  </Modal.Footer>
                </Form>
              ) : (
                <>
                  <Table striped bordered hover>
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{formData.name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Contact Name</th>
                        <td>{formData.contact_name || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Address</th>
                        <td>{formData.address || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Contact Number</th>
                        <td>{formData.contact_number || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>GST Number</th>
                        <td>{formData.gst_number || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Prefix</th>
                        <td>{formData.prefix || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Created</th>
                        <td>{formData.created.slice(0, 10) || "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Updated at</th>
                        <td>{formData.updated.slice(0, 10) || "N/A"}</td>
                      </tr>
                      {formData.logo && (
                        <tr>
                          <th>Logo</th>
                          <td>
                            <img
                              src={formData.logo}
                              alt="Company Logo"
                              style={{ width: "100px", height: "auto" }}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={handleEditToggle}>
                      Edit
                    </Button>
                    {/* Uncomment if delete is needed */}
                    {/* <Button variant="danger" onClick={openDeleteConfirmation}>
                      Delete
                    </Button> */}
                  </Modal.Footer>
                </>
              )}
            </>
          ) : (
            <p>
              Loading...{" "}
              <Loader
                className="feather-loader"
                data-bs-toggle="tooltip"
                title="feather-loader"
              />
            </p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={closeDeleteConfirmation}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this company?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDeleteConfirmation}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
function AddCompanyModal({ show, handleClose, setCompanyList }) {
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    gst_number: "",
    contact_number: "",
    contact_name: "",
    prefix: "",
  });

  const [logo, setLogo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset form data when the modal is shown
  useEffect(() => {
    if (show) {
      setFormData({
        name: "",
        address: "",
        gst_number: "",
        contact_number: "",
        contact_name: "",
        prefix: "",
      });
      setLogo(null);
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setLogo(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Create a FormData object and append the fields
    const data = new FormData();
    data.append("name", formData.name);
    data.append("address", formData.address);
    data.append("gst_number", formData.gst_number);
    data.append("contact_number", formData.contact_number);
    data.append("contact_name", formData.contact_name);
    data.append("prefix", formData.prefix);
    if (logo) {
      data.append("logo", logo); // Append the logo file to FormData
    }

    // Make an Axios POST request with the FormData
    axiosInstance
      .post(LogisticsCompaniesURL, data, {
        headers: {
          "Content-Type": "multipart/form-data", // Ensure the header is correct
        },
      })
      .then((response) => {
        console.log(response.data);
        setCompanyList((prevList) => ({
          ...prevList,
          count: prevList.count + 1,
          results: [response.data, ...prevList.results],
        }));
        setIsSubmitting(false);
        toast.success("Successfully added company");
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
        toast.error(error.response.data.gst_number[0]);
      });
  };

  return (
    <Modal centered size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label className="mb-0 mt-2">
              Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label className="mb-0 mt-2">
              Address<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formGstNumber">
            <Form.Label className="mb-0 mt-2">
              GST Number<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="gst_number"
              value={formData.gst_number}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formContactNumber">
            <Form.Label className="mb-0 mt-2">
              Contact Number<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9+]/g, ""); // Remove any disallowed characters
              }}
              name="contact_number"
              value={formData.contact_number}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formContactName">
            <Form.Label className="mb-0 mt-2">
              Contact Name<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="contact_name"
              value={formData.contact_name}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formPrefix">
            <Form.Label className="mb-0 mt-2">
              Prefix<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              type="text"
              name="prefix"
              value={formData.prefix}
              onChange={handleChange}
              required
              maxLength={100}
              minLength={1}
            />
          </Form.Group>
          <Form.Group controlId="formLogo">
            <Form.Label className="mb-0 mt-2">Upload Logo</Form.Label>
            <Form.Control
              type="file"
              name="logo"
              onChange={handleFileChange}
              accept="image/*"
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : "Save Changes"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
const LogisticsCompanies = () => {
  const [show, setShow] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);
  const handleAddModalClose = () => setShowAddModal(false);
  const handleAddModalShow = () => setShowAddModal(true);

  useEffect(() => {
    fetchCompanies(currentPage);
  }, [currentPage]);

  const fetchCompanies = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`${LogisticsCompaniesURL}`, {
        params: { page },
      });
      setCompaniesList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching companies: ", error);
      setIsLoading(false);
    }
  };

  const updateCompanyInList = (updatedCompany) => {
    setCompaniesList((prevList) => ({
      ...prevList,
      results: prevList?.results?.map((company) =>
        company.id === updatedCompany.id ? updatedCompany : company
      ),
    }));
  };

  const removeCompanyFromList = (id) => {
    setCompaniesList((prevList) => ({
      ...prevList,
      results: prevList?.results?.filter((company) => company.id !== id),
    }));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <h2 className="card-title">Company List</h2>
            <Button
              onClick={handleAddModalShow}
              variant="primary"
              className="btn btn-primary btn-sm p-2 d-flex align-items-center"
            >
              <PlusCircle
                className="feather-download me-1"
                data-bs-toggle="tooltip"
                title="feather-download"
              />
              Add Company
            </Button>
          </div>
          <Table striped bordered hover className="text-center">
            <thead className="table-secondary">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Created</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading ? (
                companiesList?.results?.length > 0 ? (
                  companiesList?.results?.map((item, index) => (
                    <tr key={item.id}>
                      <td>{(currentPage - 1) * 20 + index + 1}</td>
                      <td>{item.name || "Not available"}</td>
                      <td>{item.created.slice(0, 10) || "Not Available"}</td>
                      <td>
                        <Button
                          variant="primary"
                          className="btn btn-sm ms-1"
                          onClick={() => handleShow(item.id)}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No data</td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="4">
                    {" "}
                    loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="secondary"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </Button>
          </div>
          <ViewDetailsModal
            show={show}
            handleClose={handleClose}
            updateCompanyInList={updateCompanyInList}
            removeCompanyFromList={removeCompanyFromList}
          />
          <AddCompanyModal
            show={showAddModal}
            handleClose={handleAddModalClose}
            setCompanyList={setCompaniesList}
          />
        </div>
      </div>
    </>
  );
};

export default LogisticsCompanies;
