import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { SidebarData } from "./AdminSidebarData";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";

const AdminSideBar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const { t } = useTranslation();

  const { designation } = useSelector((state) => state.account);

  const [sidebarData, setSidebarData] = useState(SidebarData);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const expandSubMenus = (menu) => {
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      if (section.menu) {
        const updatedMenu = section.menu.map((menuItem) =>
          menu.menuValue !== menuItem.menuValue
            ? { ...menuItem, showSubRoute: false }
            : { ...menuItem, showSubRoute: !menuItem.showSubRoute }
        );
        updatedSection.menu = updatedMenu;
      }
      return section.title === menu.title ? updatedSection : section;
    });
    setSidebarData(updatedAdminSidebar);
  };

  useEffect(() => {
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      if (section.menu) {
        const updatedMenu = section.menu.map((menuItem) => {
          if (menuItem.hasSubRoute) {
            const isSubMenuActive = menuItem.subMenus.some(
              (subMenu) => pathname === subMenu.route
            );
            return { ...menuItem, showSubRoute: isSubMenuActive };
          }
          return menuItem;
        });
        updatedSection.menu = updatedMenu;
      }
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  }, [pathname]);

  const getSidebarDataWithConditionalRendering = () => {
    const allowedDesignationsForReports = [
      "Accounts Manager",
      "Zonal Manager",
      "Finance Manager",
      "Director",
      "Country Manager",
      "ADMIN",
    ];
    const allowedDesignationsForApprovals = [
      "Accounts Manager",
      "Zonal Manager",
      "Finance Manager",
      "Director",
      "Country Manager",
      "ADMIN",
      "Area Manager",
      "Country Head",
    ];

    return sidebarData.map((section) => {
      if (section.tittle === "MAIN") {
        const updatedMenu = section.menu.filter((menu) => {
          if (menu.menuValue === "Users") {
            return designation === "ADMIN";
          } else if (menu.menuValue === "Reports") {
            return allowedDesignationsForReports.includes(designation);
          } else if (menu.menuValue === "Pending Approvals") {
            return allowedDesignationsForApprovals.includes(designation);
          } else if (menu.menuValue === "Payments List") {
            return allowedDesignationsForReports.includes(designation);
          } else if (menu.menuValue === "Add Payment") {
            return allowedDesignationsForReports.includes(designation);
          }

          return true;
        });

        return { ...section, menu: updatedMenu };
      }
      return section;
    });
  };

  const renderedSidebarData = getSidebarDataWithConditionalRendering();

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul className="sidebar-vertical" id="veritical-sidebar">
              {renderedSidebarData.map((mainTittle, index) => (
                <React.Fragment key={index}>
                  <li className="menu-title">
                    <span>{t(mainTittle.tittle)}</span>
                    {mainTittle.tittle === "CRM" && (
                      <small className="newly-added-features">New</small>
                    )}
                  </li>
                  {mainTittle.menu.map((menu, menuIndex) => (
                    <React.Fragment key={menuIndex}>
                      {menu.hasSubRoute === false ? (
                        <li className={pathname === menu.route ? "active" : ""}>
                          <Link to={menu.route}>
                            <i className={menu.icon} />
                            <span>{t(menu.menuValue)}</span>
                          </Link>
                        </li>
                      ) : (
                        <li className="submenu">
                          <Link
                            to="#"
                            onClick={() => expandSubMenus(menu)}
                            className={menu.showSubRoute ? "subdrop" : ""}
                          >
                            <i className={menu.icon} />
                            <span
                              className={
                                menu.menuValue === "Employees" ? "noti-dot" : ""
                              }
                            >
                              {t(menu.menuValue)}
                            </span>
                            <span className="menu-arrow"></span>
                          </Link>
                          <ul
                            style={{
                              display: menu.showSubRoute ? "block" : "none",
                            }}
                          >
                            {menu.subMenus?.map((subMenu, subMenuIndex) => (
                              <li key={subMenuIndex}>
                                <Link
                                  to={subMenu.route}
                                  className={
                                    pathname === subMenu.route ? "active" : ""
                                  }
                                >
                                  {t(subMenu.menuValue)}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
