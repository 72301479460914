import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { headerlogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";

import { toast } from "react-toastify"; // Import toast for notifications
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LOGIN } from "../../../store/actions/accountsActions";
import { axiosInstance } from "../../../helpers/axios/axios";
import { loginUrl } from "../../../helpers/endpoints/api_endpoints";

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { jobPostingFormData } = useSelector((state) => state.jobsReducer);
  // console.log(jobPostingFormData);
  const [eye, setEye] = useState(true);

  const togglePassword = () => {
    setEye(!eye);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const authenticateUser = (data) => {
    console.log(data);
    const accessToken = data.access;
    const refreshToken = data.refresh;
    const isStaff = data.is_staff;
    const designation = data.designation;
    const userName = data.name;
    const userId = data.id;
    const payload = {
      accessToken,
      refreshToken,
      isStaff,
      userName,
      designation,
      userId,
    };
    console.log(payload);
    dispatch({ type: LOGIN, payload: payload });
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    setIsLoggingIn(false);
    // if (isStaff) {
    // }
    navigate("/dashboard");
  };
  const handleUserLogin = useCallback((data) => {
    // console.log(data);
    setIsLoggingIn(true);
    axiosInstance
      .post(`${loginUrl}`, data)
      .then((response) => {
        console.log(response.data);
        toast.success("Log in Successful");
        const responseData = response.data;
        authenticateUser(responseData);
        setIsLoggingIn(false);
      })
      .catch((error) => {
        if (error) {
          toast.error("something went wrong");
        }
        if (error.response?.data?.detail) {
          toast.error(`${error.response?.data?.detail}`);
        }
        if (error.response?.data?.email) {
          toast.error(`Please enter your email address`);
        }
        if (error.response?.data?.password) {
          toast.error(`Please enter your password`);
        }

        // if (error.response?.data?.message) {
        //   toast.error(
        //     `${error.response?.data?.message}.Please check your email...`
        //   );

        //   navigate(`/otp/${data?.email}`);
        // }
        if (error.response?.data.refresh) {
          toast.error(`Invalid email or password`);
        }

        setIsLoggingIn(false);
        console.log("login error >>>", error);
      });
  }, []);

  // useEffect(() => {
  //   setValue("email", localStorage.getItem("email"));
  //   setValue("password", localStorage.getItem("password"));
  // }, []);

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="container">
              <div className="login_page-logo">
                <img src={headerlogo} alt="Vignesh Transport" />
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Login</h3>
                  <p className="account-subtitle">
                    Please enter your login credentials
                  </p>
                  <div>
                    <form onSubmit={handleSubmit(handleUserLogin)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              // defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              placeholder="Enter your email address"
                              // value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />
                        <span className="text-danger">
                          {errors.email?.message}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                type={eye ? "password" : "text"}
                                // defaultValue={localStorage.getItem("password")}
                                // value={field.value}
                                onChange={field.onChange}
                                placeholder="Enter your password"
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={togglePassword}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            }`}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.password?.message}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        {isLoggingIn ? (
                          <button className="btn btn-primary account-btn">
                            <i className="fas fa-spinner fa-spin me-2"></i>
                            Login
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary account-btn"
                            type="submit"
                          >
                            Login
                          </button>
                        )}
                      </div>
                    </form>
                    {/* <div className="account-footer">
                      <p>
                        Don't have an account yet?{" "}
                        <Link to="/register">Register</Link>
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
