import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsActivityPhoto,
  LogisticsActivityPod,
  LogisticsApprovalPdf,
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  exportOutwardData,
  outwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { Download, Loader, PlusCircle } from "react-feather";
import { toast } from "react-toastify";
import { Select } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const DashboardOutwardTable = () => {
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [showAddDoc, setShowAddDoc] = useState(false);

  useEffect(() => {
    axiosInstance.get(outwardFormUrl).then(
      (response) => {
        console.log(response.data);
        setInwardList(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleClose = () => setShow(false);
  const handleCloseAddDoc = () => setShowAddDoc(false);
  const handleShow = (id) => setShow(id);
  const handleShowAddDoc = (id) => setShowAddDoc(id);

  const updateOutwardInList = (updatedOutward) => {
    setInwardList((prevList) => {
      console.log("Previous List:", prevList);
      return {
        ...prevList,
        results: prevList?.results?.map((outward) =>
          outward.id === updatedOutward.id ? updatedOutward : outward
        ),
      };
    });
  };

  const removeOutwardFromList = (id) => {
    setInwardList((prevList) => {
      console.log("Previous List:", prevList);
      return {
        ...prevList,
        results: prevList?.results?.filter((outward) => outward.id !== id),
      };
    });
  };

  const handleExport = async () => {
    try {
      const response = await axiosInstance.get(exportOutwardData, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outward_data.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting data: ", error);
    }
  };
  const handleDownloadFile = () => {
    axiosInstance({
      url: exportOutwardData, // Replace with your actual endpoint
      method: "GET",
      responseType: "blob", // Important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outward_data.xlsx"); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <div className="col-md-6 d-flex">
      <div className="card card-table flex-fill">
        <div className="card-header">
          <h3 className="card-title mb-0">OutWard</h3>
        </div>
        <div className="px-2">
          <div className="table-responsive">
            <Table bordered hover className="text-center">
              <thead className="table-secondary">
                <tr>
                  <th>No.</th>
                  <th>Name</th>
                  <th>Docket Number</th>
                  <th>Invoice Number</th>
                  {/* <th>Actions</th> */}
                </tr>
              </thead>
              <tbody>
                {inwardList?.count > 0 ? (
                  inwardList?.results?.slice(0, 5).map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{item?.client_details?.name || "Not available"}</td>
                      <td>{item.client_docket_number || "Not Available"}</td>
                      <td>{item.client_invoice_number || "Not Available"}</td>
                      {/* <td>
                          <Button
                            variant="primary"
                            className="btn btn-sm ms-1"
                            onClick={() => handleShow(item.id)}
                          >
                            View Details
                          </Button>
                          <Button
                            variant="primary"
                            className="btn btn-sm ms-1"
                            onClick={() => handleShowAddDoc(item.id)}
                          >
                            Add Documents
                          </Button>
                        </td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">
                      {" "}
                      loading...{" "}
                      <Loader
                        className="feather-loader"
                        data-bs-toggle="tooltip"
                        title="feather-loader"
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="card-footer">
          <Link to="/outward-list">View all Outward</Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardOutwardTable;
