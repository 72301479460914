import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  inwardFormUrl,
  NextVTDocketNumberUrl,
  outwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "../../assets/css/outward.css";
import { Loader } from "react-feather";
const OutWardForm = () => {
  const [inwardInfo, setInwardInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [nextVTDocketNumber, setNextVtDocketNumber] = useState();
  const [submitting, setSubmitting] = useState(false);
  console.log(errors);
  const { id, companyId } = useParams();

  const navigate = useNavigate();
  console.log(id, companyId);
  useEffect(() => {
    axiosInstance.get(`${inwardFormUrl}${id}/`).then(
      (response) => {
        console.log(response.data);
        setInwardInfo(response.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
    axiosInstance.get(`${NextVTDocketNumberUrl}${companyId}/`).then(
      (response) => {
        console.log(response.data);
        setNextVtDocketNumber(response.data);
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  }, [id, companyId]);

  const initialOutwardProducts = inwardInfo?.items?.map((item) => ({
    product: item.name?.id,
    weight: item.weight,
    pieces: item.number,
  }));
  console.log(initialOutwardProducts);

  const [formData, setFormData] = useState({
    client: "",
    client_docket_number: "",
    client_invoice_number: "",
    client_invoice_value: "",
    vt_docket_number: "",
    mode: "",
    sender: "",
    vehicle_number: "",
    vehicle_type: "",
    driver_staff_name: "",
    om_number: "",
    activity_cost: "",
    from_name: "",
    from_address: "",
    from_pincode: "",
    from_contact: "",
    from_email: "",
    to_name: "",
    to_address: "",
    to_pincode: "",
    to_contact: "",
    to_email: "",
    outward_products: [],
    freight: null,
    abortive: null,
    union_charge: null,
    packing: null,
    first_floor: null,
    accessories: null,
    grouting: null,
    de_grouting: null,
    dismantling: null,
    others: null,
    specify: "",
    state: "",
    client_invoice_date: "",
  });

  useEffect(() => {
    if (!loading) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        company: inwardInfo?.company || "",
        atm_id: inwardInfo?.atm_id || "",
        inward: inwardInfo?.id || "",
        client: inwardInfo?.client || "",
        state: inwardInfo?.state || "",
        client_invoice_date: inwardInfo?.client_invoice_date || "",
        sub_client: inwardInfo?.subclient_name || "",
        vt_docket_number: nextVTDocketNumber?.next_vt_docket_number || "",
        client_docket_number: inwardInfo?.client_docket_number || "",
        client_invoice_number: inwardInfo?.client_invoice_number || "",
        client_invoice_value: inwardInfo?.client_invoice_value || "",
        mode: inwardInfo?.transport_type || "",
        from_name: inwardInfo?.from_name || "",
        from_address: inwardInfo?.from_address || "",
        from_pincode: inwardInfo?.from_pincode || "",
        from_contact: inwardInfo?.from_contact || "",
        from_email: inwardInfo?.from_email || "",
        to_name: inwardInfo?.to_name || "",
        to_address: inwardInfo?.to_address || "",
        to_pincode: inwardInfo?.to_pincode || "",
        to_contact: inwardInfo?.to_contact || "",
        to_email: inwardInfo?.to_email || "",
        outward_products: initialOutwardProducts || [],
      }));
    }
  }, [inwardInfo, loading, nextVTDocketNumber]);

  // const handleBooleanChange = (name, value) => {
  //   setFormData({ ...formData, [name]: value });
  // };
  const handleBooleanChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name.toLowerCase()]: value, // update the specific activity in formData
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSelectChange = (name, value, label) => {
    const selectedValue =
      name === "mode" || name === "vehicle_type" ? label : value;
    setFormData({ ...formData, [name]: selectedValue });
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (submitting) return; // Prevent multiple submissions

    // Ensure all the additional activity fields have values
    const allActivitiesSelected = [
      formData.freight,
      formData.abortive,
      formData.union_charge,
      formData.packing,
      formData.first_floor,
      formData.accessories,
      formData.grouting,
      formData.de_grouting,
      formData.dismantling,
      formData.others,
    ].every((activity) => activity !== undefined && activity !== null);

    console.log(allActivitiesSelected);

    if (!allActivitiesSelected) {
      toast.error("Please select all options for Additional Activities.");
      setErrors((prevErrors) => ({
        ...prevErrors,
        additionalActivities: true,
      }));
      return; // Stop form submission
    }
    if (allActivitiesSelected) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        additionalActivities: false, // Clear the error state if all fields are valid
      }));
    }

    setSubmitting(true); // Disable further submissions
    const dataToSend = { ...formData };
    if (!dataToSend.client_invoice_date) {
      delete dataToSend.client_invoice_date;
    }

    console.log(dataToSend);

    axiosInstance
      .post(`${outwardFormUrl}`, dataToSend)
      .then(
        (response) => {
          console.log(response.data);
          toast.success("Form submitted successfully");
          navigate("/outward-list");
        },
        (error) => {
          console.log(error);
          if (error?.response?.data?.client) {
            toast.error("Client details cannot be empty");
          }
          if (error?.response?.data?.company) {
            toast.error("Company details cannot be empty");
          }
          if (error?.response?.data?.transport_type) {
            toast.error("Transport Type details cannot be empty");
          }
          if (error?.response?.data?.type) {
            toast.error("Type cannot be empty");
          }
        }
      )
      .finally(() => {
        setSubmitting(false); // Reset submitting state after completion
      });
  };

  const VehicleType = [{ value: "1", label: "Truck" }];

  if (loading) {
    return (
      <div>
        {" "}
        loading...{" "}
        <Loader
          className="feather-loader"
          data-bs-toggle="tooltip"
          title="feather-loader"
        />
      </div>
    );
  }
  const YesNoOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const { Option } = Select;
  const stateNames = [
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
  ];
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <h2 className="text-start mb-2">Outward Form</h2>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-4 justify-content-around">
                      <div className="col-xl-5 me-4">
                        <h4 className="card-title">Outward Information</h4>

                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Client Name
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.client_details?.name ||
                                "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Docket Number
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.client_docket_number ||
                                "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Invoice Number
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.client_invoice_number ||
                                "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Invoice Value
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {inwardInfo?.client_invoice_value ||
                                "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Mode
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.transport_type || "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Sub Client
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo.subclient_name || "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            VT Docket Number
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {formData?.vt_docket_number || "Not Available"}
                            </i>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 ms-4">
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.vt_docket_number ? "has-error" : ""
                          }`}
                        >
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              State
                            </label>
                            <div className="col-lg-8">
                              <Select
                                className="form-control"
                                options={stateNames}
                                placeholder="Select State"
                                value={
                                  stateNames.find(
                                    (option) => option.value === formData?.state
                                  ) || null
                                }
                                onChange={(value, option) =>
                                  handleSelectChange(
                                    "state",
                                    value,
                                    option.label
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.client_invoice_date ? "has-error" : ""
                          }`}
                        >
                          <div className="input-block mb-3 row align-items-center">
                            <label className="col-lg-3 col-form-label">
                              Invoice Date
                            </label>
                            <div className="col-lg-8">
                              <input
                                type="date"
                                className="form-control"
                                placeholder="Select Date"
                                value={formData.client_invoice_date}
                                onChange={(value, option) =>
                                  handleSelectChange(
                                    "state",
                                    value,
                                    option.label
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.vt_docket_number ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            VT Docket Number
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="vt_docket_number"
                              value={formData?.vt_docket_number}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div> */}
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.sender ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Sender<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="sender"
                              value={formData.sender}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.vehicle_number ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Vehicle Number<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="vehicle_number"
                              value={formData.vehicle_number}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Vehicle Type
                          </label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              type="text"
                              name="vehicle_type"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.driver_staff_name ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Driver/Staff Name
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="driver_staff_name"
                              value={formData.driver_staff_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.om_number ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            OM Number<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="om_number"
                              value={formData.om_number}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.activity_cost ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Activity Cost<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              name="activity_cost"
                              value={formData.activity_cost}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.remarks ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Remarks
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="remarks"
                              value={formData.remarks}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4 justify-content-around">
                      <div className="col-xl-5 me-4">
                        <h4 className="card-title">From Address Information</h4>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Name
                          </label>
                          <div className="col-lg-8">
                            <i> {formData.from_name || "Not Available"}</i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Address
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.from_address || "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Pincode
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.from_pincode || "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Contact
                          </label>
                          <div className="col-lg-8">
                            <i>
                              {" "}
                              {inwardInfo?.from_contact || "Not Available"}
                            </i>
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Email
                          </label>
                          <div className="col-lg-8">
                            <i>{inwardInfo?.from_email || "Not Available"}</i>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-5 ms-4">
                        <h4 className="card-title">To Address</h4>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.to_name ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Name<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_name"
                              value={formData.to_name}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.to_address ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Address<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              className="form-control"
                              name="to_address"
                              value={formData.to_address}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div
                          className={`input-block mb-3 row align-items-center ${
                            errors.to_pincode ? "has-error" : ""
                          }`}
                        >
                          <label className="col-lg-3 col-form-label">
                            Pincode<span className="text-danger">*</span>
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_pincode"
                              value={formData.to_pincode}
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Contact
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="text"
                              className="form-control"
                              name="to_contact"
                              value={formData.to_contact}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="input-block mb-3 row align-items-center">
                          <label className="col-lg-3 col-form-label">
                            Email
                          </label>
                          <div className="col-lg-8">
                            <input
                              type="email"
                              className="form-control"
                              name="to_email"
                              value={formData.to_email}
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <h4 className="card-title">
                      Additional Activities{" "}
                      <span className="text-danger">*</span>
                    </h4> */}
                    {/* <div className="row mb-4">
                      {[
                        "Freight",
                        "Abortive",
                        "Union_charge",
                        "Packing",
                        "First_Floor",
                        "Accessories",
                        "Grouting",
                        "De_Grouting",
                        "Dismantling",
                        "Others",
                      ].map((option) => (
                        <div className="col-lg-4 mb-3" key={option}>
                          <div className="input-block row align-items-center justify-content-center">
                            <label className="col-lg-3 col-form-label">
                              {option.replace("_", " ")}
                            </label>
                            <div className="col-lg-6">
                              <Select
                                placeholder="Select"
                                className="form-control"
                                name={option}
                                onChange={(value) =>
                                  handleBooleanChange(option, value)
                                }
                                required
                              >
                                {YesNoOptions.map((opt) => (
                                  <Option key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}

                    <h4 className="card-title">
                      Additional Activities{" "}
                      <span className="text-danger">*</span>
                    </h4>
                    {errors.additionalActivities && (
                      <div className="text-danger mb-3">
                        Please select an option for all activities.
                      </div>
                    )}
                    <div className="row mb-4">
                      {[
                        "Freight",
                        "Abortive",
                        "Union_charge",
                        "Packing",
                        "First_Floor",
                        "Accessories",
                        "Grouting",
                        "De_Grouting",
                        "Dismantling",
                        "Others",
                      ].map((option) => (
                        <div className="col-lg-4 mb-3" key={option}>
                          <div className="input-block row align-items-center justify-content-center">
                            <label className="col-lg-3 col-form-label">
                              {option.replace("_", " ")}
                            </label>
                            <div className="col-lg-6">
                              <Select
                                placeholder="Select"
                                className="form-control"
                                name={option}
                                onChange={(value) =>
                                  handleBooleanChange(option, value)
                                }
                                required
                              >
                                {YesNoOptions.map((opt) => (
                                  <Option key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="input-block mb-3 row align-items-center justify-content-center">
                      <label className="col-lg-1 col-form-label">Specify</label>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          name="specify"
                          value={formData.specify}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="">
                      <div className=" mx-auto">
                        <h4 className="card-title">Product Information</h4>

                        <div>
                          <table className="table table-striped text-center">
                            <thead className="table-secondary">
                              <tr>
                                <th>Item Name</th>
                                <th>Weight</th>
                                <th>Pieces</th>
                              </tr>
                            </thead>
                            {inwardInfo?.items?.map((item, index) => (
                              <tbody key={index}>
                                <tr>
                                  <td>{item?.name?.name}</td>
                                  <td>{item?.weight}</td>
                                  <td>{item?.number}</td>
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>

                    <div className="form-group text-end">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={submitting} // Disable button when submitting
                      >
                        {submitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OutWardForm;
