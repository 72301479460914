export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "HR Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/hr-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Employees",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user",
        subMenus: [
          {
            menuValue: "Add Employee",
            route: "/employees",
          },
          {
            menuValue: "All Employee",
            route: "/employeeDetails",
          },
        ],
      },
    ],
  },
];
