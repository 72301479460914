import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";

import { axiosInstance } from "../../helpers/axios/axios";
import {
  exportStockReport,
  inwardFormUrl,
  LogisticsClientsURL,
  LogisticsCompaniesURL,
} from "../../helpers/endpoints/api_endpoints";

import { Button, Dropdown, Form, Table } from "react-bootstrap";

import { Loader } from "react-feather";

import "react-datepicker/dist/react-datepicker.css";
import StockReportExport from "./StockReportExport";
import { toast } from "react-toastify";

const StockReports = () => {
  const [showExportModal, setShowExportModal] = useState(false);
  const [clientDocket, setClientDocket] = useState();
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [paymentType, setPaymentType] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [receivedDate, setReceivedDate] = useState(null);
  const [pendingAmount, setPendingAmount] = useState("");
  const [createdDate, setCreatedDate] = useState(null);
  const [type, setType] = useState(""); // State for type filter
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approvals, setApprovals] = useState("");
  const [atmId, setAtmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  useEffect(() => {
    fetchClients();
    fetchCompanies();
    fetchInwardList();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };
  const fetchInwardList = async (filters = {}, page = 1) => {
    setIsLoading(true);
    console.log(filters);
    try {
      const response = await axiosInstance.get(
        `${inwardFormUrl}?is_completed=false`,
        {
          params: { ...filters, page },
        }
      );
      console.log(response.data);
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };

  const handleExport = async (filters) => {
    try {
      const response = await axiosInstance.get(exportStockReport, {
        params: filters,
        responseType: "arraybuffer",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "stock_report.xlsx"); // or any other extension
      document.body.appendChild(link);
      link.click();
      toast.success("Report exported successfully");
    } catch (error) {
      console.error("Error exporting stock report: ", error);
    }
  };

  const handleFilterChange = () => {
    const filters = {
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      is_completed: approvals,
      atm_id: atmId,
      type: type,
      client_docket_number: clientDocket,
    };
    fetchInwardList(filters, currentPage);
  };

  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setEndDate("");
    setApprovals("");
    setAtmId("");
    setType(""); // Reset type filter
    setPaymentType("");
    setReceivedAmount("");
    setReceivedDate(null);
    setPendingAmount("");
    setCreatedDate(null);
    fetchInwardList({}, 1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      approvals: approvals,
      atm_id: atmId,
      type: type,
    };
    fetchInwardList(filters, newPage);
  };

  useEffect(() => {
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      client: selectedClient.id,
      company: selectedCompany.id,
      start_date: startDate,
      end_date: endDate,
      approvals: approvals,
      atm_id: atmId,
      type: type,
    };
    fetchInwardList(filters, currentPage);
  }, [
    paymentType,
    receivedAmount,
    receivedDate,
    pendingAmount,
    createdDate,
    selectedClient,
    selectedCompany,
    startDate,
    endDate,
    approvals,
    atmId,
    type,
    currentPage,
  ]);

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">Stock Reports</h2>
            <div className="d-flex mb-2">
              <Dropdown
                onSelect={(e) =>
                  setSelectedClient({
                    id: e,
                    name:
                      clients?.results?.find(
                        (client) => client.id === parseInt(e)
                      )?.name || "Select Client",
                  })
                }
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedClient.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {clients?.results?.map((client) => (
                    <Dropdown.Item key={client.id} eventKey={client.id}>
                      {client.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown
                className="mx-2"
                onSelect={(e) =>
                  setSelectedCompany({
                    id: e,
                    name:
                      companies?.results?.find(
                        (company) => company.id === parseInt(e)
                      )?.name || "Select Company",
                  })
                }
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {selectedCompany.name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {companies?.results?.map((company) => (
                    <Dropdown.Item key={company.id} eventKey={company.id}>
                      {company.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Form.Control
                className="mx-2"
                type="text"
                value={clientDocket}
                onChange={(e) => setClientDocket(e.target.value)}
                placeholder="Client Docket Number"
              />
              <Form.Control
                type="text"
                value={atmId}
                onChange={(e) => setAtmId(e.target.value)}
                placeholder="ATM ID"
              />
            </div>
          </div>
          <div className="text-end my-2">
            <Button
              className="me-1"
              variant="primary"
              onClick={handleFilterChange}
            >
              Apply Filters
            </Button>
            <Button variant="secondary" onClick={handleResetFilters}>
              Reset Filters
            </Button>
            <Button
              className="ms-1"
              variant="success"
              onClick={() => setShowExportModal(true)}
            >
              Export Stock Report
            </Button>
          </div>
          {isLoading ? (
            <Table className="table table-striped text-center">
              <thead className="table-secondary">
                <tr>
                  <th>sl. No</th>
                  <th>client name</th>
                  <th>Bill no.</th>
                  <th>Bill date</th>
                  <th>Bill amount</th>
                  <th>Payment Type</th>
                  <th>Pending Amount</th>
                  <th>Received Amount</th>
                  <th>Received Date</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td colSpan="9">
                    Loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <div className="table-responsive">
              <Table
                striped
                bordered
                hover
                className="table table-striped text-center"
              >
                <thead className="table-secondary">
                  <tr>
                    <th>sl. No</th>
                    <th>Client Name</th>
                    <th>Client Docket No.</th>
                    <th>From Address</th>
                    <th> To Address</th>
                    <th>Product Name</th>
                    <th>Quantity</th>
                    <th>Weight</th>
                  </tr>
                </thead>
                <tbody>
                  {inwardList?.results?.length > 0 ? (
                    inwardList.results.map((inward, index) => (
                      <tr key={inward.id}>
                        <td>{(currentPage - 1) * 20 + index + 1}</td>
                        <td>{inward.client_details?.name || "NA"}</td>
                        <td>{inward.client_docket_number || "NA"}</td>
                        <td>{inward.from_address || "NA"}</td>
                        <td>{inward.to_address || "NA"}</td>
                        <td>
                          {inward.items.length > 0
                            ? inward.items?.map((i) => i.name.name)
                            : "NA"}
                        </td>
                        <td>
                          {inward.items.length > 0
                            ? inward.items?.map((i) => i.number || "NA")
                            : "NA"}{" "}
                        </td>
                        <td>
                          {inward.items.length > 0
                            ? inward.items?.map((i) => i.weight || "NA")
                            : "NA"}{" "}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No data</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </div>
          <StockReportExport
            show={showExportModal}
            handleClose={() => setShowExportModal(false)}
            clients={clients}
            onExport={handleExport}
          />
        </div>
      </div>
    </>
  );
};

export default StockReports;
