import React, { useEffect, useState } from "react";
import Header from "../../views/layout/Header";
import AdminSideBar from "../../views/layout/AdminSideBar";
import Breadcrumbs from "../../components/Breadcrumbs";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsClientsURL,
  LogisticsCompaniesURL,
  LogisticsPaymentRegister,
  exportInWardData,
  inwardFormUrl,
} from "../../helpers/endpoints/api_endpoints";
import { Link } from "react-router-dom";
import { Button, Dropdown, Form, Modal, Table } from "react-bootstrap";
import { Select } from "antd";
import { toast } from "react-toastify";
import { Loader } from "react-feather";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PaymentList = () => {
  const [inwardList, setInwardList] = useState([]);
  const [show, setShow] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    id: "",
    name: "Select Client",
  });
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: "Select Company",
  });
  const [paymentType, setPaymentType] = useState("");
  const [receivedAmount, setReceivedAmount] = useState("");
  const [receivedDate, setReceivedDate] = useState(null);
  const [pendingAmount, setPendingAmount] = useState("");
  const [createdDate, setCreatedDate] = useState(null);
  const [type, setType] = useState(""); // State for type filter
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [approvals, setApprovals] = useState("");
  const [atmId, setAtmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleClose = () => setShow(false);
  const handleShow = (id) => setShow(id);

  useEffect(() => {
    fetchCompanies();
    fetchClients();
    fetchInwardList();
  }, []);

  const fetchClients = async () => {
    try {
      const response = await axiosInstance.get(LogisticsClientsURL);
      setClients(response.data);
    } catch (error) {
      console.error("Error fetching clients: ", error);
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get(LogisticsCompaniesURL);
      setCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies: ", error);
    }
  };
  const fetchInwardList = async (filters = {}, page = 1) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(LogisticsPaymentRegister, {
        params: { ...filters, page },
      });
      console.log(response.data);
      setInwardList(response.data);
      setTotalPages(Math.ceil(response.data.count / 20)); // Assuming 20 items per page
    } catch (error) {
      console.error("Error fetching inward list: ", error);
    }
    setIsLoading(false);
  };

  const handleFilterChange = () => {
    const filters = {
      payment_type: paymentType,
      received_amount: receivedAmount,
      received_date: receivedDate
        ? receivedDate.toISOString().split("T")[0]
        : "",
      pending_amount: pendingAmount,
      created_date: createdDate ? createdDate.toISOString().split("T")[0] : "",
      start_date: startDate ? startDate.toISOString().split("T")[0] : "",
      end_date: endDate ? endDate.toISOString().split("T")[0] : "",
      client: selectedClient?.id,
      company: selectedCompany?.id,
    };
    fetchInwardList(filters, currentPage);
  };

  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setSelectedCompany({ id: "", name: "Select Company" });
    setStartDate("");
    setEndDate("");
    setApprovals("");
    setAtmId("");
    setType(""); // Reset type filter
    setPaymentType("");
    setReceivedAmount("");
    setReceivedDate(null);
    setPendingAmount("");
    setCreatedDate(null);
    fetchInwardList({}, 1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    handleFilterChange(newPage);
  };
  useEffect(() => {
    handleFilterChange();
  }, [
    paymentType,
    receivedAmount,
    receivedDate,
    pendingAmount,
    createdDate,
    startDate,
    endDate,
    selectedClient?.id,
    selectedCompany?.id,
  ]);

  // const renderUniqueClientNames = (clients) => {
  //   const uniqueNames = new Set();
  //   clients.forEach((client) => {
  //     if (client.sales?.outwards_details) {
  //       uniqueNames.add(client.sales.client.name);
  //     }
  //   });
  //   return Array.from(uniqueNames).join(", ");
  // };
  const renderUniqueNames = (details, key) => {
    const uniqueNames = new Set();
    details.forEach((e) => {
      if (e[key]?.name) {
        uniqueNames.add(e[key]?.name);
      }
    });
    return Array.from(uniqueNames).map((name, index) => (
      <li key={index} className="text-start">
        {name}
      </li>
    ));
  };
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h2 className="text-start">Payments List</h2>
          </div>
          <div className="d-flex mb-2 justify-content-center align-items-center">
            <Dropdown
              onSelect={(e) =>
                setSelectedClient({
                  id: e,
                  name:
                    clients?.results?.find(
                      (client) => client.id === parseInt(e)
                    )?.name || "Select Client",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedClient.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {clients?.results?.map((client) => (
                  <Dropdown.Item key={client.id} eventKey={client.id}>
                    {client.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown
              className="mx-2"
              onSelect={(e) =>
                setSelectedCompany({
                  id: e,
                  name:
                    companies?.results?.find(
                      (company) => company.id === parseInt(e)
                    )?.name || "Select Company",
                })
              }
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {selectedCompany.name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {companies?.results?.map((company) => (
                  <Dropdown.Item key={company.id} eventKey={company.id}>
                    {company.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown onSelect={(e) => setPaymentType(e)}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {paymentType ? paymentType : "Select Payment Type"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item eventKey="TDS">TDS</Dropdown.Item>
                <Dropdown.Item eventKey="Receipt">Receipt</Dropdown.Item>
                <Dropdown.Item eventKey="Round off">Round off</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Form.Control
              className="mx-2"
              type="text"
              value={receivedAmount}
              onChange={(e) => setReceivedAmount(e.target.value)}
              placeholder="Received Amount"
            />
            <Form.Control
              className="mx-2"
              type="text"
              value={pendingAmount}
              onChange={(e) => setPendingAmount(e.target.value)}
              placeholder="Pending Amount"
            />

            <DatePicker
              className="form-control mx-2 "
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="Start Date"
              dateFormat="yyyy-MM-dd"
            />
            <DatePicker
              className="form-control ms-3"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              dateFormat="yyyy-MM-dd"
            />
          </div>

          <div className="text-end my-2">
            <Button
              className="me-2"
              variant="primary"
              onClick={handleFilterChange}
            >
              Apply Filters
            </Button>
            <Button variant="secondary" onClick={handleResetFilters}>
              Reset Filters
            </Button>
          </div>
          {isLoading ? (
            <Table className="table table-striped text-center">
              <thead className="table-secondary">
                <tr>
                  <th>sl. No</th>
                  <th>client name</th>
                  <th>Bill no.</th>
                  <th>Bill date</th>
                  <th>Bill amount</th>
                  <th>Payment Type</th>
                  <th>Pending Amount</th>
                  <th>Received Amount</th>
                  <th>Received Date</th>
                </tr>
              </thead>
              <tbody className="text-center">
                <tr>
                  <td colSpan="9">
                    Loading...{" "}
                    <Loader
                      className="feather-loader"
                      data-bs-toggle="tooltip"
                      title="feather-loader"
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <div className="table-responsive">
              {/* <Table className="table table-striped text-center">
                <thead className="table-secondary">
                  <tr>
                    <th>sl. No</th>
                    <th>client name</th>
                    <th>Bill no.</th>
                    <th>Bill date</th>
                    <th>Bill amount</th>
                    <th>Payment Type</th>
                    <th>Pending Amount</th>
                    <th>Received Amount</th>
                    <th>Received Date</th>
                  </tr>
                </thead>
                <tbody>
                  {inwardList?.results?.map((inward, index) => (
                    <tr key={inward.id}>
                      <td>{index + 1}</td>
                      <td>sss</td>
                      <td>
                        {inward.sales?.bill_number
                          ? inward.sales?.bill_number
                          : "NA"}
                      </td>

                      <td>
                        {inward.sales?.bill_date
                          ? inward.sales?.bill_date
                          : "NA"}
                      </td>
                      <td>
                        {inward.sales?.total_invoice
                          ? inward.sales?.total_invoice
                          : "NA"}
                      </td>
                      <td>
                        {inward.payment_type ? inward.payment_type : "NA"}
                      </td>
                      <td>
                        {inward.pending_amount ? inward.pending_amount : "NA"}
                      </td>
                      <td>
                        {inward.received_amount ? inward.received_amount : "NA"}
                      </td>
                      <td>
                        {inward.received_date ? inward.received_date : "NA"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table> */}
              <Table
                striped
                bordered
                hover
                className="table table-striped text-center"
              >
                <thead className="table-secondary">
                  <tr>
                    <th>sl. No</th>
                    <th>client name</th>
                    <th>Bill no.</th>
                    <th>Bill date</th>
                    <th>Bill amount</th>
                    <th>Payment Type</th>
                    <th>Pending Amount</th>
                    <th>Received Amount</th>
                    <th>Received Date</th>
                  </tr>
                </thead>
                <tbody>
                  {inwardList?.results?.length > 0 ? (
                    inwardList.results.map((inward, index) => (
                      <tr key={inward.id}>
                        <td>{index + 1}</td>
                        <td>
                          {/* {inward.sales?.outwards_details
                            ? renderUniqueClientNames([inward])
                            : "NA"} */}
                          {inward?.sales_details?.outwards_details?.length > 0
                            ? renderUniqueNames(
                                inward?.sales_details?.outwards_details,
                                "outward_client_name"
                              )
                            : "Not available"}
                        </td>
                        <td>
                          {inward.sales_details?.bill_number
                            ? inward.sales_details.bill_number
                            : "NA"}
                        </td>
                        <td>
                          {inward.sales_details?.bill_date
                            ? inward.sales_details.bill_date
                            : "NA"}
                        </td>
                        <td>
                          {inward.sales_details?.total_invoice
                            ? inward.sales_details.total_invoice
                            : "NA"}
                        </td>
                        <td>
                          {inward.payment_type ? inward.payment_type : "NA"}
                        </td>
                        <td>
                          {inward.pending_amount ? inward.pending_amount : "NA"}
                        </td>
                        <td>
                          {inward.received_amount
                            ? inward.received_amount
                            : "NA"}
                        </td>
                        <td>
                          {inward.received_date ? inward.received_date : "NA"}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="9">No data</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center mt-3">
            <Button
              variant="primary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              variant="primary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentList;
