import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { axiosInstance } from "../../helpers/axios/axios";
import {
  LogisticsProductsURL,
  exportStockReport,
} from "../../helpers/endpoints/api_endpoints";

const StockReportExport = ({ show, handleClose, clients, onExport }) => {
  const [selectedClient, setSelectedClient] = useState("");
  const [clientDocketNumber, setClientDocketNumber] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axiosInstance.get(LogisticsProductsURL);
      setProducts(response.data.results || []);
    } catch (error) {
      console.error("Error fetching products: ", error);
    }
  };

  const handleExport = async () => {
    const filters = {
      client_id: selectedClient,
      client_docket_number: clientDocketNumber,
      product_id: selectedProduct,
    };
    onExport(filters);
    handleClose();
  };
  const handleResetFilters = () => {
    setSelectedClient({ id: "", name: "Select Client" });
    setClientDocketNumber("");
    setSelectedProduct("");
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Export Stock Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown onSelect={(e) => setSelectedClient(e)} className="mb-2">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {clients?.results?.find(
              (client) => client.id === parseInt(selectedClient)
            )?.name || "Select Client"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {clients?.results?.map((client) => (
              <Dropdown.Item key={client.id} eventKey={client.id}>
                {client.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown onSelect={(e) => setSelectedProduct(e)} className="mt-3">
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {products.find(
              (product) => product.id === parseInt(selectedProduct)
            )?.name || "Select Product"}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {products.map((product) => (
              <Dropdown.Item key={product.id} eventKey={product.id}>
                {product.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Control
          className="mt-3"
          type="text"
          placeholder="Client Docket Number"
          value={clientDocketNumber}
          onChange={(e) => setClientDocketNumber(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="secondary" onClick={handleResetFilters}>
          Reset Filters
        </Button>
        <Button variant="primary" onClick={handleExport}>
          Export
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StockReportExport;
